import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ScrollToTop from 'components/scrollToTop/scrollToTop';
import store from 'redux/store';
import { theme } from 'styles/themes';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundaryWrapper from 'utils/ErrorBoundary';
import './styles/index.css';

const RootComponent: React.FC = () => (
  <ErrorBoundaryWrapper>
    <App />
  </ErrorBoundaryWrapper>
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Provider store={store}>
        <Router basename='/'>
          <ScrollToTop />
          <RootComponent />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface ManagementHeaderProps {
  handleChangeTabType: (newValue: 'Tutors' | 'Institutions') => void;
  tabType: 'Tutors' | 'Institutions';
  classes: any;
}

const ManagementHeader: React.FC<ManagementHeaderProps> = ({
  classes,
  handleChangeTabType,
  tabType,
}) => {
  return (
    <div className={classes.authOptions}>
      <ToggleButtonGroup
        className={classes.toogleButton}
        exclusive
        aria-label='Platform'
      >
        <ToggleButton
          className={`${classes.toggleButtonHalf} ${
            tabType === 'Tutors'
              ? classes.selectedToggle
              : classes.notSelectedToggle
          }`}
          onClick={() => handleChangeTabType('Tutors')}
          value='Tutors'
        >
          Tutors
        </ToggleButton>
        <ToggleButton
          className={`${classes.toggleButtonHalf} ${
            tabType === 'Institutions'
              ? classes.selectedToggle
              : classes.notSelectedToggle
          }`}
          onClick={() => handleChangeTabType('Institutions')}
          value='Institutions'
        >
          Institutions
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ManagementHeader;

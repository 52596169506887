import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Loading from 'components/loading/Loading';
import ProductItem from 'pages/products/ItemProduct';
import React, { Suspense, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import combineStyles from 'utils/combineStyles';

import { getAllProducts } from 'services/productService';

import { Category, LibraryProduct } from 'mapfilm-api';
import { isSeller } from 'services/authService';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import FiltersSideBar from 'components/filtersSideBar/filtersSideBar';

import CollectionsHeader from './CollectionsHeader';

interface OwnProps {
  classes?: any;
  history: any;
  match: any;
}

const CollectionsPage: React.FC<OwnProps> = ({ classes }) => {
  const [products, setProducts] = useState([] as LibraryProduct[]);
  const [loading, setLoading] = useState(true as boolean);
  const [filteredProducts, setFilteredProducts] = useState(
    [] as LibraryProduct[]
  );
  const [searchQuery, setSearchQuery] = useState('' as string);
  const countriesArr = [
    'East of England',
    'England',
    'Ireland',
    'London',
    'Midlands',
    'North of England',
    'Northeast',
    'Northeast Ireland',
    'Northwest',
    'Scotland',
    'Southeast',
    'Southwest',
    'Wales',
  ] as string[];
  const [filters, setFilters] = useState({
    rightsOwner: 'placeholder',
    regions: [] as string[],
    year: [1900, 2024],
    duration: [0, 220],
    themes: [] as string[],
  });
  const defaultValues = {
    rightsOwner: 'placeholder',
    regions: [],
    year: [1900, 2024],
    duration: [0, 220],
    themes: [] as string[],
  };
  const [themesList, setThemesList] = useState([] as Category[]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  useEffect(() => {
    getAllProducts(isSeller()).then(
      (response) => {
        setProducts(response.products);
        setFilteredProducts(response.products);
        setLoading(false);
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
  }, []);

  useEffect(() => {
    console.log(filteredProducts);
  }, [filteredProducts]);

  useEffect(() => {
    if (searchQuery) {
      const preFilteredProducts = products.filter(
        (product: LibraryProduct) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.synopsis?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(preFilteredProducts);
    } else if (JSON.stringify(filters) !== JSON.stringify(defaultValues)) {
      let preFilteredProducts = products.filter((product: LibraryProduct) => {
        const productYear = product.year as number;
        const productDuration = product.duration as number;
        return (
          productYear <= filters.year[1] &&
          productYear >= filters.year[0] &&
          productDuration <= filters.duration[1] &&
          productDuration >= filters.duration[0]
        );
      });
      if (filters.themes.length > 0) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return product.genres?.some(
              (genre: any) =>
                filters.themes.find((theme: any) => theme.id === genre) !==
                undefined
            );
          }
        );
      }
      setFilteredProducts(preFilteredProducts);
    } else {
      setFilteredProducts(products);
    }
  }, [searchQuery, filters]);

  const CollectionsLoading = (
    <>
      <CollectionsHeader />
      <Loading />
    </>
  );

  return (
    <Suspense fallback={CollectionsLoading}>
      <CollectionsHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid container>
            <Grid item xs={9}>
              {typeof products !== 'undefined' &&
              filteredProducts.length === 0 ? (
                <Typography className={classes.noProducts}>
                  No film products!
                </Typography>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ marginRight: '20px' }}>
                    <Grid container spacing={3}>
                      {filteredProducts.map(
                        (product: LibraryProduct, index: number) => (
                          <Grid
                            key={product.id + index.toString()}
                            item
                            xs={12}
                          >
                            <NavLink
                              key={index}
                              className={classes.productLink}
                              to={'/movies/' + product.id}
                            >
                              <ProductItem
                                key={index + '_product'}
                                poster={product.posterUrl}
                                title={product.name}
                                director={product.director}
                                duration={product.duration}
                                year={product.year}
                                synopsis={product.synopsis}
                                genres={product.genres}
                                genreList={themesList}
                                movie={false}
                              />
                            </NavLink>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={3}>
              <FiltersSideBar
                countriesArr={countriesArr as string[]}
                filters={filters}
                setFilters={setFilters}
                defaultValues={defaultValues}
                themesList={themesList}
                setThemesList={setThemesList}
                products={products as LibraryProduct[]}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Suspense>
  );
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles as any)(CollectionsPage);

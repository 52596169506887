import { createStyles } from '@material-ui/styles';

import { SECONDARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  filterBox: {
    transition: 'all .5s ease-in-out',
    /*border: '1px solid #D8996F',*/
    borderRadius: 10,
  },
  filtersFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    /*padding: 30,*/
    '& >*:first-child': {
      marginLeft: 0,
    },
    '& >*:last-child': {
      marginRight: 0,
    },
    '& >*': {
      flexGrow: 1,
      flexBasis: '25%',
      paddingLeft: 25,
      paddingRight: 10,
      paddingTop: 30,
      paddingBottom: 30,
      border: `1px solid ${SECONDARY_COLOR}`,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
    },
  },
  filterTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: SECONDARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    marginBottom: 15,
    letterSpacing: '0.02em',
  },
  filterContent: {
    maxHeight: 140,
    overflow: 'auto',
  },

  /* Slider styling */
  sliderContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    paddingRight: 15,
  },
  sliderTxtContainer: {
    textAlign: 'center',
  },
  sliderTxt: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7A7A7A',
  },
  sliderLink: {
    fontWeight: 800,
    cursor: 'pointer',
    color: SECONDARY_COLOR,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export default useStyles;

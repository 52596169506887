import { createStyles } from '@material-ui/styles';

import { PRIMARY_COLOR, SECONDARY_COLOR, THIRD_COLOR } from './colors';

const useStyles = createStyles({
  activeFilters: {
    color: THIRD_COLOR,
    backgroundColor: '#7A7A7A',
    '&:hover': {
      opacity: 0.8,
    },
  },
  searchField: {
    width: '40% !important',
    borderRadius: '5px',
    backgroundColor: '#e8e8e8',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiOutlinedInput-input': {
      height: '10px !important',
      border: 'none !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  productLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover .movie-image-hover': {
      opacity: 0.8,
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  productItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  productImgContainer: {
    position: 'relative',
    paddingBottom: '100%',
    maxWidth: '100%',
  },
  productImage: {
    position: 'relative',
    width: '110px',
    height: '160px',
    objectFit: 'cover',
    borderRadius: '10px',
    zIndex: 1,
  },
  collectionImage: {
    position: 'relative',
    width: '110px',
    height: '160px',
    borderRadius: '10px',
    objectFit: 'cover',
    zIndex: 1,
  },
  imageHover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '110px',
    height: '160px',
    borderRadius: '10px',
    zIndex: 2,
    backgroundColor: SECONDARY_COLOR,
    opacity: 0,
    transition: 'opacity .3s ease-out',
    '-moz-transition': 'opacity .3s ease-out',
    '-webkit-transition': 'opacity .3s ease-out',
    '-o-transition': 'opacity .3s ease-out',
    '& .MuiSvgIcon-root': {
      fontSize: 36,
      color: THIRD_COLOR,
    },
  },
  productHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  productTitle: {
    paddingTop: 15,
    color: PRIMARY_COLOR,
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: '0.02em',
  },
  productDirector: {
    paddingTop: 8,
    color: '#7A7A7A',
    fontSize: 14,
    fontWeight: 400,
  },
  productGenre: {
    paddingTop: 13,
  },
  productSinopsis: {
    paddingTop: 8,
    color: PRIMARY_COLOR,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'justify',
    marginRight: '13px',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  genreItem: {
    color: THIRD_COLOR,
    backgroundColor: PRIMARY_COLOR,
    padding: '10px',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: '10px',
    letterSpacing: '0.02em',
    marginRight: '10px',
  },
  buttonsContainer: {
    '&>*:first-child': {
      marginRight: 20,
    },
  },
  footerBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  searchHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 70,
  },
  searchResults: {
    fontSize: 28,
    color: SECONDARY_COLOR,
    textAlign: 'center',
    fontWeight: 700,
  },
  searchHighlight: {
    color: SECONDARY_COLOR,
  },
  noProducts: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0.5,
    color: '#7A7A7A',
    textAlign: 'center',
  },
  cardStyles: {
    display: 'flex',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important',
  },
});

export default useStyles;

import { createStyles } from '@material-ui/styles';

import {
  FOURTH_COLOR,
  HOVER_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
} from 'styles/colors';

const useStyles = createStyles({
  toolbarLogo: {
    backgroundColor: FOURTH_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 72,
  },
  sidebar: {
    width: 330,
    position: 'relative',
    backgroundColor: FOURTH_COLOR,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: 'none',
    zIndex: 1000,
  },
  itemsContainer: {
    marginTop: 40,
  },
  sidebarItemLink: {
    textDecoration: 'none',
    color: THIRD_COLOR,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
    '&.active .MuiListItem-root': {
      backgroundColor: THIRD_COLOR,
      color: SECONDARY_COLOR,
    },
  },
  sidebarItem: {
    height: 64,
    '&:hover': {
      color: SECONDARY_COLOR,
      backgroundColor: THIRD_COLOR,
    },
    '&:hover .MuiListItemIcon-root': {
      color: HOVER_COLOR,
    },
  },
  sidebarItemIcon: {
    color: 'currentColor',
    marginLeft: 30,
    minWidth: 45,
  },
  sidebarItemTxt: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.02em',
  },
  sidebarFooter: {
    position: 'absolute',
    width: '100%',
    bottom: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '22px',
    textAlign: 'center',
  },
  sidebarFooterTxt: {
    color: '#7a7a7a',
    fontWeight: 500,
    letterSpacing: '0.2px',
    marginTop: 10,
  },
  eitLogo: {
    width: 250,
    marginTop: 10,
  },
});

export default useStyles;

import { Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import MSlider from 'components/controls/slider/Slider';
import { stepCalculator } from 'utils/utils';

import FilterStyles from './FStyles';

interface OwnProps {
  classes?: any;
  minValue: number;
  maxValue: number;
  range: number[];
  handleChange: (e: any, newValue: number | number[]) => void;
  commitChanges: (e: any, newValue: number | number[]) => void;
  resetSlider: () => void;
}

function FilterYear(props: OwnProps) {
  const { classes } = props;

  const marks = [
    {
      value: props.minValue,
      label: props.minValue,
    },
    {
      value: props.maxValue,
      label: props.maxValue,
    },
  ];

  const step = stepCalculator(props.maxValue - props.minValue);
  return (
    <div className={classes.singleFilterContainer}>
      <div className={classes.filterTitle}>Film Year</div>

      <div className={classes.sliderContent}>
        <div className={classes.sliderTxtContainer}>
          <Typography classes={{ root: classes.sliderTxt }} variant='body2'>
            Current range:
            <br />
            {props.range[0]} - {props.range[1]}
          </Typography>
        </div>

        <MSlider
          value={props.range}
          step={step}
          minValue={props.minValue}
          maxValue={props.maxValue}
          marks={marks}
          handleChange={props.handleChange}
          handleChangeCommit={props.commitChanges}
        />

        <div className={classes.sliderTxtContainer}>
          <Link
            classes={{ root: classes.sliderLink }}
            underline='none'
            onClick={() => props.resetSlider()}
          >
            Clear
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withStyles(FilterStyles)(FilterYear);

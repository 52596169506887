import { FormLabel, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import MediaForm from 'pages/products/addFilms/AddMediaVersion';

import Styles from 'styles/dashboard';

import { CreateProductModel, LibraryProductVersion } from 'mapfilm-api';
import { ProductVersionForm } from 'models/ProductsModel';

import { useQuery } from 'react-query';
import { getProductMedia } from 'services/productService';

interface Props {
  product: CreateProductModel;
  classes: any;

  handleAddForm: any;
  handleRemoveForm: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: any;
  handleFilmStatusQuery: (
    status: {
      index: number;
      status: string;
      id: string;
    }[]
  ) => void;
  productMedia: ProductVersionForm[];
}

const AddMedia: React.FC<Props> = ({
  classes,
  handleAddForm,
  handleRemoveForm,
  handleInputChange,
  handleDropChange,
  handleFilmVersionAdd,
  handleFilmStatusQuery,
  productMedia,
}) => {
  // add setProductMedia to state

  const { isLoading, isError, data, error } = useQuery(
    'vodsToUpload',
    async () => {
      //create uuidList with all uuid from media that are not undefined
      const uuidList = productMedia
        .map((media: ProductVersionForm) => media.uuid)
        .filter((uuid: string | undefined) => uuid !== undefined);
      if (uuidList.length !== 0) {
        const media = await getProductMedia(uuidList as string[]);

        console.log('media', media);

        productMedia.forEach((mediaItem: ProductVersionForm) => {
          const hasMedia = media.find(
            (item: LibraryProductVersion) => item.uuid === mediaItem.uuid
          );
          if (!hasMedia) {
            if (mediaItem.uuid) mediaItem.status = 'UPLOADING';
          } else {
            mediaItem.status = hasMedia.status;
            mediaItem.version = hasMedia.name;
            mediaItem.vizziID = hasMedia.id;
          }
        });
        return productMedia;
      }
    }
  );

  React.useEffect(() => {
    console.log(isLoading, isError, data);
    if (!isLoading && !isError && data) {
      // create new object list with index and status
      const updatedProductMedia = data.map((media: any, index: number) => {
        return {
          index: index,
          status: media.status,
          id: media.vizziID,
        };
      });

      handleFilmStatusQuery(updatedProductMedia); // Update the productMedia prop
    }
  }, []);

  return (
    <React.Fragment>
      <div>
        <Grid container>
          <Grid item container sm={12} alignItems='flex-start'>
            <Grid item container spacing={8}>
              <Grid item sm={12}>
                <FormLabel
                  className={classes.labelBlack}
                  style={{ marginTop: '30px' }}
                  component='legend'
                >
                  MEDIA
                </FormLabel>
                {productMedia.map(
                  (formData: ProductVersionForm, index: number) => (
                    <MediaForm
                      key={index}
                      formData={formData}
                      index={index}
                      classes={classes}
                      handleInputChange={handleInputChange}
                      handleDropChange={handleDropChange}
                      handleFilmVersionAdd={handleFilmVersionAdd}
                      handleRemoveForm={handleRemoveForm}
                    />
                  )
                )}
              </Grid>
            </Grid>

            <button
              className={classes.dashboardButtonAddMedia}
              type='submit'
              onClick={handleAddForm}
            >
              + Add media
            </button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(AddMedia);

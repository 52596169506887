import { api } from './customAxios';

const getAllBespokeLicenses = async () => {
  try {
    const response = await api.get('/license/bespoke');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getContractPreview = async (id: string) => {
  try {
    const response = await api.get(`/license/preview/${id}`, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPendingLicenses = async () => {
  try {
    const response = await api.get('/license', {
      params: {
        status: 'prepare',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSignedLicenses = async () => {
  try {
    const response = await api.get('/license', {
      params: {
        status: 'finalised',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getBespokeLicense = async (productId: string) => {
  try {
    const response = await api.get(`/license/bespoke/${productId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const CreateLicense = async (
  vizziID: string,
  duration: number,
  price: number
) => {
  try {
    const response = await api.post(`/license/bespoke/`, {
      vizziID: vizziID,
      duration: duration,
      price: price,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const FinalizeLicense = async (licenseID: string) => {
  try {
    const response = await api.patch(`/license/${licenseID}`, {
      status: 'finalised',
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const PrepareLicensePurchase = async (vizziID: string) => {
  try {
    const response = await api.post(`/license/${vizziID}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response.data.message;
    console.log('message: ' + error.message);
    throw new Error(errorMessage ? errorMessage : 'Purchase license error.');
  }
};

const DownloadContract = (id: string): string => {
  return `${api.defaults.baseURL}/license/download/${id}`;
};

export {
  CreateLicense,
  DownloadContract,
  FinalizeLicense,
  PrepareLicensePurchase,
  getAllBespokeLicenses,
  getBespokeLicense,
  getContractPreview,
  getPendingLicenses,
  getSignedLicenses,
};

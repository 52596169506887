import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Component } from 'react';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = createStyles({
  inputContainer: {
    display: 'flex',
    width: '100%',
    background: THIRD_COLOR,
    fontSize: 16,
    color: 'black',
    lineHeight: 1.2,
    padding: '0 20px 0 10px',
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    height: 48,
    '-webkit-transition': 'border 500ms ease-out',
    '-moz-transition': 'border 500ms ease-out',
    '-o-transition': 'border 500ms ease-out',
    transition: 'border 500ms ease-out',
    '&.Mui-error': {
      border: '1px solid red',
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  inputTxt: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 'unset',
    '&::placeholder': {
      color: '#7A7A7A',
      opacity: 1,
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
    '&:-webkit-autofill:hover': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-box-shadow': ' 0 0 0 30px white inset',
    },
  },
  selectInput: {
    paddingLeft: 20,
    '&:focus': {
      background: 'unset',
    },
  },
  selectIcon: {
    marginRight: 12,
  },
  focused: {
    boxShadow: '2px 2px #ccc !important',
    '-moz-box-shadow': '2px 2px #ccc !important',
    '-webkit-box-shadow': '2px 2px #ccc !important',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: THIRD_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
    '&.Mui-focused': {
      color: `${THIRD_COLOR} !important`,
    },
    '&.Mui-error': {
      color: THIRD_COLOR,
    },
  },
  labelBlack: {
    fontSize: 16,
    fontWeight: 700,
    color: PRIMARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
    '&.Mui-focused': {
      color: `${PRIMARY_COLOR} !important`,
    },
    '&.Mui-error': {
      color: PRIMARY_COLOR,
    },
  },
});

class TextInput extends Component<any> {
  render() {
    const {
      id,
      classes,
      type,
      name,
      value,
      setInputValue,
      placeholder,
      disabled,
      required,
      label,
      select,
      children,
      error,
      helperText,
      pageType,
      ...props
    } = this.props;

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    const isDefaultValue = value === '';
    let selectClasses = 'select-input';
    if (isDefaultValue) selectClasses += ' class-font-colour-gray';

    const setInputFunction = (value: any) => {
      if (setInputValue) {
        setInputValue(value);
      }
    };

    const InputElement = (
      <Input
        id={id}
        classes={{
          root: classes.inputContainer,
          input: classes.inputTxt,
          focused: classes.focused,
        }}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        disableUnderline
        fullWidth
        disabled={disabled}
        required={required ? true : false}
        onChange={(e: any) => setInputFunction(e)}
        {...props}
      />
    );
    return (
      <FormControl className='form-input' error={error} fullWidth>
        <FormLabel
          htmlFor={id}
          id={inputLabelId}
          className={pageType == 'product' ? classes.labelBlack : classes.label}
          component='legend'
        >
          {label}
        </FormLabel>

        {select ? (
          <Select
            classes={{ select: classes.selectInput, icon: classes.selectIcon }}
            className={selectClasses}
            aria-describedby={helperTextId}
            id={id}
            labelId={inputLabelId}
            value={value}
            input={InputElement}
            displayEmpty
          >
            {children}
          </Select>
        ) : (
          InputElement
        )}

        {helperText && (
          <FormHelperText id={helperTextId}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(useStyles as any)(TextInput);

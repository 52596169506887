import React, { useEffect, useState } from 'react';

import { getAllInstitutions } from 'services/orgService';
import { getAllStaffUsers } from 'services/userService';

import { withStyles } from '@material-ui/styles';
import Styles from 'styles/management';
import ManagementHeader from './ManagementHeader';
import ManagementTable from './ManagementTable';

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';

import { changeOrgInfo, deleteOrg } from 'services/orgService';
import { changeUserStatus, deleteUser } from 'services/userService';

import { PatchOrgInfoModel } from 'mapfilm-api';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

interface ManagementProps {
  classes?: any;
}

const queryClient = new QueryClient();
const ManagementMainWrapper: React.FC<ManagementProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ManagementMain {...props} />
    </QueryClientProvider>
  );
};

const ManagementMain: React.FC<ManagementProps> = ({ classes }) => {
  const [usersData, setUsersData] = useState([] as any);
  const [orgsData, setOrgsData] = useState([] as any);
  const [tabType, setTabType] = useState<'Tutors' | 'Institutions'>('Tutors');

  const handleChangeTabType = (newValue: 'Tutors' | 'Institutions') => {
    setTabType(newValue);
  };

  const getAllStaffUsersMutation = useMutation(() => getAllStaffUsers(), {
    onError: (error: any) => {
      console.log(error);
    },
    onSuccess: (data) => {
      setUsersData(data);
    },
  });

  const getAllInstitutionsMutation = useMutation(() => getAllInstitutions(), {
    onError: (error: any) => {
      console.log(error);
    },
    onSuccess: (data) => {
      setOrgsData(data);
    },
  });

  const useMutationWithAlert = (
    mutationFn: (...args: any[]) => Promise<void>,
    successMessage: string,
    errorMessage: string
  ) => {
    return useMutation(mutationFn, {
      onError: (error: any) => {
        console.log(error.message);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          iconColor: PRIMARY_COLOR,
          text: errorMessage || error.message,
          showConfirmButton: false,
          customClass: {
            container: classes.swalContainer,
          },
        });
      },
      onSuccess: () => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: successMessage,
          showConfirmButton: false,
          customClass: {
            container: classes.swalContainer,
          },
        });
        getAllInstitutionsMutation.mutate();
        getAllStaffUsersMutation.mutate();
      },
    });
  };

  const userApproveMutation = useMutationWithAlert(
    ({ userId, status }: { userId: number; status: string }) =>
      changeUserStatus(userId, status),
    'User approved!',
    'Failed to approve user'
  );

  const userDeleteMutation = useMutationWithAlert(
    ({ userId, email }: { userId: number; email: string }) =>
      deleteUser(userId, email),
    'User deleted!',
    'Failed to delete user'
  );

  const orgApproveMutation = useMutationWithAlert(
    ({ id, info }: { id: number; info: PatchOrgInfoModel }) =>
      changeOrgInfo(id, info),
    'Institution approved!',
    'Error approving institution!'
  );

  const orgEditMutation = useMutationWithAlert(
    ({ id, info }: { id: number; info: PatchOrgInfoModel }) =>
      changeOrgInfo(id, info),
    'Institution edited!',
    'Failed to edit institution'
  );

  const orgDeleteMutation = useMutationWithAlert(
    ({ id }: { id: number }) => deleteOrg(id),
    'Institution deleted!',
    'Failed to delete institution'
  );

  useEffect(() => {
    getAllStaffUsersMutation.mutate();

    if (getAllStaffUsersMutation.isSuccess) {
      setUsersData(getAllStaffUsersMutation.data);
    }
  }, []);

  useEffect(() => {
    getAllInstitutionsMutation.mutate();

    if (getAllInstitutionsMutation.isSuccess) {
      setOrgsData(getAllInstitutionsMutation.data);
    }
  }, []);

  return (
    <>
      <ManagementHeader
        classes={classes}
        handleChangeTabType={handleChangeTabType}
        tabType={tabType}
      />
      <ManagementTable
        usersData={usersData}
        orgsData={orgsData}
        userApproveMutation={userApproveMutation}
        userDeleteMutation={userDeleteMutation}
        orgApproveMutation={orgApproveMutation}
        orgEditMutation={orgEditMutation}
        orgDeleteMutation={orgDeleteMutation}
        tabType={tabType}
      />
    </>
  );
};

export default withStyles(Styles as any)(ManagementMainWrapper);

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import { CustomLicenseModel, DialogModel, LicenseTemplateModel } from 'models';
import ContractPreview from 'pages/products/ContractPreview';
import { RootState } from 'redux/store';
import { PrepareLicensePurchase } from 'services/educationalLicenseService';
import { getUser } from 'services/userService';

import Styles from 'styles/dashboard';

interface OwnProps {
  classes?: any;
  history: any;
  match: any;
}

interface StateProps {
  customLicense: CustomLicenseModel;
  licenseTemplate: LicenseTemplateModel;
}

interface State {
  loading: boolean;
  disabledBtn: boolean;
  licenseError: string;
  hasOrg: boolean;
  dialog: DialogModel;
}

type Props = StateProps & OwnProps;

class CustomizeLicensePage extends React.Component<Props, State> {
  state = {
    loading: true,
    disabledBtn: true,
    licenseError: '',
    hasOrg: false,
    dialog: {
      open: false,
      setOpenPopup: this.openContractPreview.bind(this),
      title: 'Contract Preview',
    },
  };

  async componentDidMount() {
    /* Runs after the render */
    await getUser().then((user) => {
      // On run

      if (user.org) {
        this.setState((prevState) => ({
          ...prevState,
          hasOrg: true,
        }));
      }
    });
  }

  addToCart = async () => {
    const productId = this.props.match.params.id;
    this.setState({ loading: true });

    await PrepareLicensePurchase(productId).then(
      (value) => {
        // On run
        this.setState({ loading: false });
        this.props.history.push('/myCustomLicenses');
        // window.location.reload();
      },
      (reason) => {
        // on fail
        this.setState({ loading: false, licenseError: reason });
        console.log(reason);
      }
    );
  };

  setDisabledState = (disabled: boolean) => {
    console.log('disabled', disabled);
    this.setState({ disabledBtn: disabled });
  };

  setLoadingState = (loading: boolean) => {
    this.setState({ loading: loading });
  };

  openContractPreview(dialogValue: boolean) {
    this.setState((prevState) => {
      const dialog = Object.assign({}, prevState.dialog);
      dialog.open = dialogValue;
      return { dialog };
    });
  }

  render() {
    const { classes } = this.props;
    const productId = this.props.match.params.id;
    return (
      <div className={classes.content}>
        <div className={classes.headerWithoutMargin}>
          <Typography variant='h5' className={classes.dashboardSubtitleBlue}>
            Customize License
          </Typography>
        </div>

        <Grid container>
          <Grid item xs={12} md={12}>
            {this.state.hasOrg ? (
              <LicenseWindow
                licenseType='custom'
                productId={productId}
                licenseId=''
                operationMode='create'
                setDisabledState={this.setDisabledState.bind(this)}
                loading={this.state.loading}
                setLoadingState={this.setLoadingState.bind(this)}
              />
            ) : (
              <></>
            )}
            <div className={classes.footerContainer}>
              <NavLink
                className={classes.dashLinkBtn}
                to={`/movies/${productId}`}
              >
                <Button className={classes.secondaryBtn} variant='outlined'>
                  Back to Film
                </Button>
              </NavLink>

              <Button
                className={classes.dashboardButton}
                variant='outlined'
                disableRipple
                disabled={this.state.hasOrg ? this.state.disabledBtn : true}
                onClick={() => {
                  // this.addToCart()
                  this.setState((prevState) => {
                    const dialog = Object.assign({}, prevState.dialog);
                    dialog.open = true;
                    return { dialog };
                  });
                }}
              >
                Purchase License
              </Button>
            </div>
          </Grid>
        </Grid>

        <ContractPreview
          openPopup={this.state.dialog.open}
          setOpenPopup={this.state.dialog.setOpenPopup}
          dialogTitle={this.state.dialog.title}
          collectionId={productId}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  customLicense: state.customLicense,
  licenseTemplate: state.licenseTemplate,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(Styles as any)(CustomizeLicensePage));

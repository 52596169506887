declare global {
  interface Window {
    PROTOCOL: string;
    API_URL: string;
    API_PORT: number;
    API_PATH: string;
    PROJECT: string;
  }
}

const settings = {
  PROTOCOL: window.PROTOCOL,
  API_URL: window.API_URL,
  API_PORT: window.API_PORT,
  API_PATH: window.API_PATH,
  PROJECT: window.PROJECT,
};

export default settings;

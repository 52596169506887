import { Checkbox, FormLabel, TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Chip from '@mui/material/Chip';
import React, { ChangeEvent } from 'react';

import Styles from './AutocompleteStyles';

interface OwnProps {
  id: string;
  classes?: any;
  label: string;
  name: string;
  value: any;
  pageType: any;
  options: any[];
  placeholder: string;
  disabled?: boolean;
  auth: boolean;
  required: boolean;
  getOptionLabel: (option: any) => string;
  onChange: (
    event: ChangeEvent<unknown>,
    newValue: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  getOptionSelected: (option: any, value: any) => boolean;
  selectedOptions: any;
}

class AutocompleteInputSelects extends React.Component<OwnProps> {
  render() {
    const {
      classes,
      label,
      id,
      options,
      value,
      disabled,
      placeholder,
      auth,
      required,
      getOptionLabel,
      getOptionSelected,
      onChange,
      selectedOptions,
      pageType,
    } = this.props;

    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    return (
      <>
        <FormLabel
          className={
            pageType === 'product' ? classes.labelBlack : classes.label
          }
          component='legend'
        >
          {label}
        </FormLabel>
        <Autocomplete
          classes={{
            root: classes.autocompleteRoot,
            inputRoot: auth
              ? classes.autocompleteInputAuthSelect
              : classes.autocompleteInput,
          }}
          id={id}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onChange={onChange}
          value={value}
          disabled={disabled ? disabled : false}
          multiple
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {getOptionLabel(option)}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant='outlined'
              required={required}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
                className={classes.chipLabel}
                // style={{backgroundColor:'#000000'}}
              />
            ))
          }
        />
      </>
    );
  }
}

export default withStyles(Styles as any)(AutocompleteInputSelects);

import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';

import { project } from 'project';

import { UserActions } from 'redux/actions';
import { isLoggedIn } from 'services/authService';
import { getUser } from 'services/userService';
import Dropdown from 'templates/dropdown/Dropdown';
import HeaderStyles from './HeaderStyles';

import LogoScene from 'assets/logo/logo-scene.png';
import LogoMap from 'assets/logo/logo-white.svg';

interface OwnProps {
  classes?: any;
  history: any;
  open: boolean;
  handleDrawerOpen: (payload: any) => void;
}

const Header: React.FC<OwnProps> = ({ classes, history, open }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUser() {
      try {
        const value = await getUser();
        dispatch(UserActions.updateUser(value));
      } catch (error) {
        localStorage.clear();
        history.push(`/auth/login`);
      }
    }
    fetchUser();
  }, [dispatch]);

  if (!isLoggedIn()) {
    return <Redirect to='/auth/login' />;
  }

  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.categoryDropdown}>
          <NavLink to='/catalogue'>
            {project === 'scene' ? (
              <img
                className={`${classes.logoImgScene} ${classes.logoImg}`}
                src={LogoScene}
                alt='Logo'
              />
            ) : (
              <img className={classes.logoImg} src={LogoMap} alt='Logo' />
            )}
          </NavLink>
          {/*<span className="navbar-categories">
                          Category
                      </span>
                      <span className="expand-more">
                          <ExpandMoreIcon
                              classes={{ root: classes.expandIcon }}
                              fontSize="inherit"
                          />
                      </span>*/}
        </Typography>
        {/* <Search history={history} /> */}
        <Dropdown />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(HeaderStyles)(Header);

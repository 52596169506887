import { FormLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import Styles from './AutocompleteStyles';

interface OwnProps {
  id: string;
  classes?: any;
  label: string;
  name: string;
  value: any;
  pageType: any;
  options: any[];
  placeholder: string;
  disabled?: boolean;
  auth: boolean;
  required: boolean;
  getOptionLabel: (option: any) => string;
  onChange: (event: any, newValue: string | null, reason: string) => void;
  getOptionSelected: (option: any, value: any) => boolean;
}

class AutocompleteInput extends React.Component<OwnProps> {
  render() {
    const {
      classes,
      label,
      id,
      options,
      value,
      disabled,
      placeholder,
      auth,
      required,
      getOptionLabel,
      getOptionSelected,
      onChange,
      pageType,
    } = this.props;

    return (
      <>
        <FormLabel
          className={
            pageType === 'product' ? classes.labelBlack : classes.label
          }
          component='legend'
        >
          {label}
        </FormLabel>
        <Autocomplete
          classes={{
            root: classes.autocompleteRoot,
            inputRoot: auth
              ? classes.autocompleteInputAuth
              : classes.autocompleteInput,
          }}
          id={id}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onChange={onChange}
          value={value}
          disabled={disabled ? disabled : false}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant='outlined'
              required={required}
            />
          )}
        />
      </>
    );
  }
}

export default withStyles(Styles as any)(AutocompleteInput);

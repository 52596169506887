import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import MLogout from 'components/logout/Logout';
import { UserModel } from 'models';
import { UserActions } from 'redux/actions';
import { isSeller, logout } from 'services/authService';

import AssignmentIcon from '@material-ui/icons/Assignment';
import MovieIcon from '@material-ui/icons/Movie';
import PersonIcon from '@material-ui/icons/Person';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import GroupsIcon from '@mui/icons-material/Groups';

import { project } from 'project';

import { HOVER_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

interface DropdownOptionsProps {
  user: UserModel;
  homePage: string;
  blockchain: boolean;
  updateUser: any;
  setToggle: any;
}

const useStyles = makeStyles({
  dropdownIcon: {
    fontSize: 24,
    width: 50,
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '15px 25px',
    clear: 'both',
    whiteSpace: 'nowrap',
    border: 0,
    color: THIRD_COLOR,
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'none',
      color: SECONDARY_COLOR,
      backgroundColor: THIRD_COLOR,
      border: 'none',
      outline: 'none',
    },
    '&:hover svg': {
      color: HOVER_COLOR,
    },
    '&:focus': {
      textDecoration: 'none',
      color: SECONDARY_COLOR,
      backgroundColor: THIRD_COLOR,
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      color: SECONDARY_COLOR,
      backgroundColor: THIRD_COLOR,
      border: 'none',
      outline: 'none',
    },
  },
});

function DropdownOptions(props: DropdownOptionsProps) {
  const classes = useStyles();

  return (
    <>
      <Link
        className='dropdown-item'
        classes={{ root: classes.dropdownItem }}
        onClick={(e: any) => props.setToggle(false)}
        component={NavLink}
        activeClassName='active'
        to='/catalogue'
      >
        <MovieIcon classes={{ root: classes.dropdownIcon }} />
        {isSeller() ? 'My Products' : 'Products'}
      </Link>

      {isSeller() && project !== 'mfh' ? (
        <Link
          className='dropdown-item'
          classes={{ root: classes.dropdownItem }}
          onClick={(e: any) => props.setToggle(false)}
          component={NavLink}
          activeClassName='active'
          to='/myBespokeLicenses'
        >
          <AssignmentTurnedInIcon classes={{ root: classes.dropdownIcon }} />
          My Bespoke Licenses
        </Link>
      ) : (
        <></>
      )}

      {project !== 'mfh' ? (
        <Link
          className='dropdown-item'
          classes={{ root: classes.dropdownItem }}
          onClick={(e: any) => props.setToggle(false)}
          component={NavLink}
          activeClassName='active'
          to='/myPendingLicenses'
        >
          <AssignmentTurnedInIcon classes={{ root: classes.dropdownIcon }} />
          My Pending Licenses
        </Link>
      ) : (
        <></>
      )}
      <Link
        className='dropdown-item'
        classes={{ root: classes.dropdownItem }}
        onClick={(e: any) => props.setToggle(false)}
        component={NavLink}
        activeClassName='active'
        to='/myCustomLicenses'
      >
        <AssignmentIcon classes={{ root: classes.dropdownIcon }} />
        My Signed Licenses
      </Link>

      <Link
        className='dropdown-item'
        classes={{ root: classes.dropdownItem }}
        onClick={(e: any) => props.setToggle(false)}
        component={NavLink}
        activeClassName='active'
        to='/profile'
      >
        <PersonIcon classes={{ root: classes.dropdownIcon }} />
        Profile
      </Link>

      {props.user.role === 'admin' ? (
        <Link
          className='dropdown-item'
          classes={{ root: classes.dropdownItem }}
          onClick={(e: any) => props.setToggle(false)}
          component={NavLink}
          activeClassName='active'
          to='/management'
        >
          <GroupsIcon classes={{ root: classes.dropdownIcon }} />
          Management
        </Link>
      ) : (
        <></>
      )}

      <Link
        href='/auth/login'
        className='dropdown-item'
        classes={{ root: classes.dropdownItem }}
        onClick={async () => {
          await logout();
        }}
      >
        <MLogout />
        Sign out
      </Link>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  homePage: state.homePage,
  blockchain: state.blockchain,
});

const mapActionsToProps = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(DropdownOptions);

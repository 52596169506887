import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import CheckboxField from 'components/controls/checkboxfield/CheckboxField';
import FilterStyles from './FStyles';

interface OwnProps {
  classes?: any;
  genreList: string[];
  genreValues: string[];
  handleGenreSelect: any;
}

class FilterGenres extends React.Component<OwnProps> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.singleFilterContainer}>
        <div className={classes.filterTitle}>Genres</div>

        <div className={classes.filterContent}>
          <FormGroup>
            {this.props.genreList.map((key, index) => (
              <div key={`genres-${index}`}>
                <CheckboxField
                  name='genres'
                  label={key}
                  value={key}
                  checkedValues={this.props.genreValues}
                  handleSelect={this.props.handleGenreSelect}
                />
              </div>
            ))}
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default withStyles(FilterStyles)(FilterGenres);

import { Redirect, Route, Switch } from 'react-router-dom';

import CollectionsPage from 'pages/collections/CollectionsPage';
import CreateLTemplatePage from 'pages/licenses/forms/CreateLTemplatePage';
import CustomizeLicensePage from 'pages/licenses/forms/CustomizeLicensePage';
import CLlist from 'pages/licenses/view/CLlist';
import LTlist from 'pages/licenses/view/LTlist';
import LicenseTemplate from 'pages/licenses/view/LicenseTemplate';
import PLlist from 'pages/licenses/view/PLlist';
import Management from 'pages/management/Management';
import ProductPage from 'pages/product/ProductPage';
import CreateProductPage from 'pages/products/CreateProductPage';
import ProductsPage from 'pages/products/ProductsPage';
import ProfilePage from 'pages/profile/Profile';
import SearchPage from 'pages/search/Search';
//import CustomLicense from 'pages/licenses/view/CustomLicense'
import { isLoggedIn } from 'services/authService';

const AppRouter = (props: any) => {
  if (!isLoggedIn()) {
    return <Redirect to={{ pathname: '/auth/login' }} />;
  }

  return (
    <Switch>
      {/* Movie Collections */}
      <Route path='/catalogue/:id' component={ProductsPage} />
      <Route path='/catalogue' component={CollectionsPage} />
      {/* Movies */}
      <Route path='/movies/create' component={CreateProductPage} />
      <Route path='/movies/:id/createLicense' component={CreateLTemplatePage} />
      <Route
        path='/movies/:id/customizeLicense'
        component={CustomizeLicensePage}
      />
      <Route path='/movies/:id' component={ProductPage} />
      <Route
        path='/movies/:productId/myBespokeLicenses/:templateId'
        component={LicenseTemplate}
      />
      {/*<Route path="/movies" component={ProductsPage} />*/}
      <Route path='/searchResults' component={SearchPage} />
      <Route path='/profile' component={ProfilePage} />
      {/*<Route path="/movies/:productId/myBespokeLicenses/:templateId" component={LicenseTemplate} />*/}
      {/* Licenses */}
      <Route
        path='/myBespokeLicenses/:templateId'
        component={LicenseTemplate}
      />
      <Route path='/myBespokeLicenses' component={LTlist} />
      {/*<Route path="/myCustomLicenses/:id" component={CustomLicense} />*/}
      <Route path='/myCustomLicenses' component={CLlist} />
      <Route path='/management' component={Management} />
      <Route path='/myPendingLicenses' component={PLlist} />
      <Redirect to={{ pathname: '/catalogue' }} />;
    </Switch>
  );
};

export default AppRouter;

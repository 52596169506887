import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = createStyles({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    overflow: 'hidden',
    marginTop: '5%',
  },
  item: {
    listStyleType: 'none',
    transition: '0.5s all ease',
    width: 20,
    height: 20,
    margin: 10,
  },
  rightAnimation: {
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'alternate',
  },
  right1: {
    backgroundColor: SECONDARY_COLOR,
    animationName: '$right-1',
    animationDelay: '100ms',
  },
  right2: {
    backgroundColor: PRIMARY_COLOR,
    animationName: '$right-2',
    animationDelay: '200ms',
  },
  right3: {
    backgroundColor: THIRD_COLOR,
    animationName: '$right-3',
    animationDelay: '300ms',
  },
  right4: {
    backgroundColor: PRIMARY_COLOR,
    animationName: '$right-4',
    animationDelay: '400ms',
  },
  right5: {
    backgroundColor: SECONDARY_COLOR,
    animationName: '$right-5',
    animationDelay: '500ms',
  },
  '@keyframes right-1': {
    '0%': {
      transform: 'translateY(-60px)',
    },
    '100%': {
      transform: 'translateY(60px)',
    },
  },
  '@keyframes right-2': {
    '0%': {
      transform: 'translateY(-70px)',
    },
    '100%': {
      transform: 'translateY(70px)',
    },
  },
  '@keyframes right-3': {
    '0%': {
      transform: 'translateY(-80px)',
    },
    '100%': {
      transform: 'translateY(80px)',
    },
  },
  '@keyframes right-4': {
    '0%': {
      transform: 'translateY(-90px)',
    },
    '100%': {
      transform: 'translateY(90px)',
    },
  },
  '@keyframes right-5': {
    '0%': {
      transform: 'translateY(-100px)',
    },
    '100%': {
      transform: 'translateY(100px)',
    },
  },
});

export default useStyles;

import { project } from 'project';

let PRIMARY_COLOR: string,
  SECONDARY_COLOR: string,
  THIRD_COLOR: string,
  FOURTH_COLOR: string,
  HOVER_COLOR: string;

switch (project) {
  case 'map':
    PRIMARY_COLOR = '#000000';
    SECONDARY_COLOR = '#D8996F';
    THIRD_COLOR = '#FFFFFF';
    FOURTH_COLOR = '#252525';
    HOVER_COLOR = 'rgba(216,153,111, 0.9)';
    break;
  case 'mfh':
    PRIMARY_COLOR = '#000000';
    SECONDARY_COLOR = '#D8996F';
    THIRD_COLOR = '#FFFFFF';
    FOURTH_COLOR = '#252525';
    HOVER_COLOR = 'rgba(216,153,111, 0.9)';
    break;
  case 'scene':
    PRIMARY_COLOR = '#150958';
    SECONDARY_COLOR = '#A482BB';
    THIRD_COLOR = '#FFFFFF';
    FOURTH_COLOR = '#3F2D4C';
    HOVER_COLOR = 'rgba(141, 100, 170, 0.9)';
    break;
  default:
    PRIMARY_COLOR = '#000000';
    SECONDARY_COLOR = '#D8996F';
    THIRD_COLOR = '#FFFFFF';
    FOURTH_COLOR = '#252525';
    HOVER_COLOR = 'rgba(216,153,111, 0.9)';
}

export {
  FOURTH_COLOR,
  HOVER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
};

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
const strokeColor = '#E0E0E0';

export const ExternalContainer = {
  width: '100%',
  height: '100%',
  background: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
};

export const TopContainer = {
  width: '100%',
  height: '200px',
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: `1px solid ${strokeColor}`,
  position: 'relative',
  zIndex: 2,

  leftSide: {
    height: '100%',
    width: '50%',
    display: 'flex',
  },
  rightSide: {
    height: '100%',
    width: '50%',
    display: 'flex',
  },
  image: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userInformation: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    '& h4': {
      fontWeight: 'light',
      fontSize: '16px',
      marginTop: '5px',
    },
  },
  filmCounter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '50px',
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& h3': {
        fontWeight: 'bold',
        fontSize: '24px',
      },
      '& h4': {
        fontWeight: 'light',
        fontSize: '18px',
        marginTop: '5px',
      },
    },
  },
};

export const BottomContainer = {
  marginTop: '20px',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: `1px solid ${strokeColor}`,
  position: 'relative',
  zIndex: 2,

  container: {
    width: '100%',
    height: '100%',
    padding: '25px',
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '40px',
  },
  title: {
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& h4': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  icon: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& svg': {
      '&:hover': {
        color: SECONDARY_COLOR,
        cursor: 'pointer',
      },
    },
  },
  information: {
    height: '100%',
    width: '100%',
  },
  dataRow: {
    borderBottom: `1px solid ${strokeColor}`,
    display: 'flex',
    width: '100%',
    height: '48px',
    marginTop: '15px',
    leftSide: {
      width: '50%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    rightSide: {
      width: '50%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  },
  footer: {
    paddingTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    leftSide: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '50%',
      '& button': {
        backgroundColor: SECONDARY_COLOR,
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
        },
      },
    },
    rightSide: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
};

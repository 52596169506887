import { FormLabel, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Component } from 'react';

import { PRIMARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: PRIMARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
    '&.Mui-focused': {
      color: `${PRIMARY_COLOR} !important`,
    },
    '&.Mui-error': {
      color: PRIMARY_COLOR,
    },
  },
  description: {
    fontSize: 16,
    color: 'black',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
});

interface MFieldDataProps {
  label: string;
  description: string;
  classes: any;
}

class FieldData extends Component<MFieldDataProps> {
  render() {
    const { classes } = this.props;

    return (
      <div className='form-data'>
        <FormLabel classes={{ root: classes.label }} component='legend'>
          {this.props.label}
        </FormLabel>
        {this.props.description ? (
          <Typography classes={{ root: classes.description }} component='div'>
            {this.props.description}
          </Typography>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withStyles(useStyles as any)(FieldData);

import { CustomLicenseModel, LicenseTemplateModel } from 'models';
import { api } from 'services/customAxios';

const createTemplate = async (productSN: string, lt: LicenseTemplateModel) => {
  try {
    const response = await api.post('/licenseTemplates/create', {
      productSN: productSN,
      licenseTemplate: lt,
    });
    return response.data;
  } catch (error: any) {
    console.log('message: ' + error.message);
    throw error;
  }
};

const createTemplateBFI = async (productSN: string) => {
  try {
    const response = await api.post('/licenseTemplates/createBFI', {
      productSN: productSN,
    });
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response.data.message;
    console.log('message: ' + error.message);
    throw new Error(
      errorMessage ? errorMessage : 'License template creation error.'
    );
  }
};

const getLicenseTemplateByProductId = async (productId: string) => {
  try {
    const response = await api.get(`/licenseTemplates/product`, {
      params: {
        productId: productId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLicenseTemplateByProductBFI = async (
  productId: string,
  role: string
) => {
  try {
    const url = role === 'seller' ? 'bfiproductFromSeller' : 'bfiproduct';
    const response = await api.get(`/licenseTemplates/` + url, {
      params: {
        productId: productId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLicenseTemplateById = async (licenseTemplateId: string) => {
  try {
    const response = await api.get(`/licenseTemplates/${licenseTemplateId}`, {
      params: {},
    });
    return response.data.licenseTemplate[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLicenseTemplates = async () => {
  try {
    const response = await api.get('/licenseTemplates/');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getBFILicenseTemplates = async () => {
  try {
    const response = await api.get('/licenseTemplates/bfiproducts');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const addCustomLicenseToCart = async (
  productSN: string,
  licenseTemplate: LicenseTemplateModel,
  customLicense: CustomLicenseModel
) => {
  try {
    const response = await api.post('/cart', {
      productSN: productSN,
      customLicense: customLicense,
      licenseTemplate: licenseTemplate,
    });
    return response.data;
  } catch (error: any) {
    console.log('message: ' + error.message);
    throw error;
  }
};

const addBFILicenseToCart = async (productSN: string) => {
  try {
    const response = await api.post('/cart/bfi', {
      productSN: productSN,
    });
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response.data.message;
    console.log('message: ' + error.message);
    throw new Error(errorMessage ? errorMessage : 'Purchase license error.');
  }
};

export {
  addBFILicenseToCart,
  addCustomLicenseToCart,
  createTemplate,
  createTemplateBFI,
  getBFILicenseTemplates,
  getLicenseTemplateById,
  getLicenseTemplateByProductBFI,
  getLicenseTemplateByProductId,
  getLicenseTemplates,
};

import { createStyles } from '@material-ui/styles';

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  tableContainer: {
    borderRadius: 10,
    boxShadow: 'none',
    border: '1px solid #D7D7D7',
  },
  tableHead: {
    '& .MuiTableCell-head': {
      color: SECONDARY_COLOR,
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
      borderRight: '1px solid #D7D7D7',
    },
  },
  tableBody: {
    '& .MuiTableCell-body': {
      color: PRIMARY_COLOR,
      fontSize: 16,
      padding: 20,
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
      borderRight: '1px solid #D7D7D7',
    },
  },
  downloadLink: {
    color: SECONDARY_COLOR,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  downloadIcon: {
    fill: SECONDARY_COLOR,
    marginRight: 5,
  },
  noLicenses: {
    textAlign: 'center',
  },
  licenseState: {
    color: 'rgb(122, 122, 122, 0.8)',
    fontWeight: 500,
  },
});

export default useStyles;

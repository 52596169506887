const commercialRightsDB: any = {
  Exhibitions: {
    'Cinematic Rights': ['Theatrical', 'NonTheatrical', 'Open'],
    'Ancilliary Rights': [
      'Airline',
      'Hotel',
      'Ship',
      'Train',
      'Commercial Vehicle',
    ],
  },
  TV: {
    'Distribution Channels': ['Cable', 'Satellite', 'Terrestrial', 'Catch-Up'],
    'Subscription Methods': ['Free', 'Basic', 'Premium'],
  },
  VOD: {
    Type: ['AdVOD', 'FVOD', 'SVOD', 'TVOD', 'Internet Streaming'],
  } /*,
    "PayPerView": {
        "Location Type": ["Residencial", "NonResidencial"]
    },
    "EST": {
        "Type": ["Single Use", "Limited Use", "Extended Use", "Internet Downloading"]
    },
    "Video": {
        "Type": ["Video Rental", "SellThrough"]
    }*/,
};

const nonCommercialRightsDB: any = {
  Educational: {
    institutions: [],
    Type: ['COURSE', 'PPR', 'Both'],
    price: -1,
    duration: 0,
  },
};

export { commercialRightsDB, nonCommercialRightsDB };

import React from 'react';
// import moment from 'moment';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import {
  FinalizeLicense,
  getPendingLicenses,
} from 'services/educationalLicenseService';
/*import {
    commercialRightsDB,
    nonCommercialRightsDB
} from 'database/rightsDB'*/
import Loading from 'components/loading/Loading';
import combineStyles from 'utils/combineStyles';

import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';

import { UserModel } from 'models';

import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isSeller } from 'services/authService';

interface OwnProps {
  classes: any;
  history: any;
}
interface State {
  licenses: any[];
  loading: boolean;
}

interface StateProps {
  user: UserModel;
}
type Props = StateProps & OwnProps;

class PLlist extends React.Component<Props, State> {
  state = {
    licenses: [],
    loading: true,
  };

  async componentDidMount() {
    await getPendingLicenses().then(
      (value) => {
        // On run
        this.setState({
          licenses: value,
          loading: false,
        });
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
  }

  async finalizeLicense(licenseID: string) {
    // this.setState({ loading: true });
    await FinalizeLicense(licenseID).then(
      (value) => {
        // On run
        this.setState({ loading: false });
        this.props.history.push('/myCustomLicenses');
        // window.location.reload();
      },
      (reason) => {
        // on fail
        //this.setState({ loading: false });
        console.log(reason);
      }
    );
  }

  render() {
    const { classes } = this.props;
    console.log('licensas', this.state.licenses);
    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <Typography className={classes.dashboardTitle} variant='h4'>
            My Pending Licenses
          </Typography>
        </div>

        {this.state.loading ? (
          <Loading />
        ) : (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Film Title</TableCell>
                  <TableCell>Licensee Organization</TableCell>
                  <TableCell>Licensee Name</TableCell>
                  <TableCell>Licensee Email</TableCell>
                  <TableCell>{isSeller() ? 'Options' : 'State'}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {this.state.licenses.length === 0 ? (
                  <TableRow>
                    <TableCell className={classes.noLicenses} colSpan={5}>
                      No entries were found.
                    </TableCell>
                  </TableRow>
                ) : (
                  this.state.licenses.map((row: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell>
                        <NavLink
                          className={classes.downloadLink}
                          to={`/movies/${row.TitleID}`}
                        >
                          {row.FilmTitle}
                        </NavLink>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.Licensee}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.LicenseeAuthorised}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.LicenseeEmail}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {isSeller() ? (
                          <Button
                            className={classes.dashboardButton}
                            variant='outlined'
                            onClick={this.finalizeLicense.bind(
                              this,
                              row.LicenseID
                            )}
                          >
                            Finalize License
                          </Button>
                        ) : (
                          <Typography className={classes.licenseState}>
                            Pending Approval...
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </React.Fragment>
    );
  }
}
const combinedStyles = combineStyles(DashStyles, Styles);

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(combinedStyles)(PLlist));

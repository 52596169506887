import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import React from 'react';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Lock';
import UserIcon from '@mui/icons-material/Person';

import TextInput from 'components/controls/textInput/TextInput';
import TextArea from 'components/controls/textarea/TextArea';

interface TutorFormProps {
  classes: any;
  form: any;
  errors: any;
  inputStyle: any;
  confirmPassword: any;
  showPassword: any;
  showConfirmPassword: any;
  handleClickShowConfirmPassword: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickShowPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandle: (field: string) => void;
}

const TutorForm: React.FC<TutorFormProps> = ({
  classes,
  form,
  errors,
  inputStyle,
  confirmPassword,
  showPassword,
  showConfirmPassword,
  handlePasswordChange,
  handleClickShowConfirmPassword,
  handleChange,
  handleClickShowPassword,
  onBlurHandle,
}) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextInput
          id='firstName'
          className={`${inputStyle('firstNameError')}`}
          type='text'
          label='First Name'
          name='firstName'
          placeholder='Enter your first name'
          value={form.firstName}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          autoComplete='given-name'
          onBlur={() => onBlurHandle('firstName')}
          helperText={errors.firstNameError}
          error={errors.firstNameError !== ''}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          id='lastName'
          className={`${inputStyle('lastNameError')}`}
          type='text'
          label='Last Name'
          name='lastName'
          placeholder='Enter your last name'
          value={form.lastName}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          autoComplete='family-name'
          onBlur={() => onBlurHandle('lastName')}
          helperText={errors.lastNameError}
          error={errors.lastNameError !== ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id='username'
          className={`${inputStyle('usernameError')}`}
          type='text'
          label='Username'
          name='username'
          placeholder='Choose your username'
          value={form.username}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          autoComplete='username'
          onBlur={() => onBlurHandle('username')}
          helperText={errors.usernameError}
          error={errors.usernameError !== ''}
          startAdornment={
            <InputAdornment
              classes={{ positionStart: classes.positionStart }}
              position='start'
            >
              <UserIcon />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id='email'
          className={`${inputStyle('emailError')}`}
          type='email'
          label='Academic Email Address'
          name='email'
          placeholder='Enter your email address'
          value={form.email}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          autoComplete='email'
          onBlur={() => onBlurHandle('email')}
          helperText={errors.emailError}
          error={errors.emailError !== ''}
          startAdornment={
            <InputAdornment
              classes={{ positionStart: classes.positionStart }}
              position='start'
            >
              <EmailIcon />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          id='password'
          className={`${inputStyle('passwordError')}`}
          type={showPassword ? 'text' : 'password'}
          label='Password'
          name='password'
          placeholder='Choose your password'
          value={form.password}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          autoComplete='current-password'
          onBlur={() => onBlurHandle('password')}
          helperText={errors.passwordError}
          error={errors.passwordError !== ''}
          startAdornment={
            <InputAdornment
              classes={{ positionStart: classes.positionStart }}
              position='start'
            >
              <PasswordIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              classes={{ positionEnd: classes.positionEndRegister }}
              position='end'
            >
              <IconButton
                classes={{ root: classes.iconButton }}
                aria-label='toggle password visibility'
                onClick={(e: any) => handleClickShowPassword(e)}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                {showPassword ? (
                  <VisibilityOff style={{ fill: 'black' }} />
                ) : (
                  <Visibility style={{ fill: 'black' }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          id='confirmPassword'
          className={`${inputStyle('confirmPasswordError')}`}
          type={showConfirmPassword ? 'text' : 'password'}
          label='Confirm Password'
          name='confirmPassword'
          placeholder='Confirm your password'
          value={confirmPassword}
          setInputValue={handlePasswordChange}
          disabled={false}
          required={true}
          autoComplete='current-password'
          onBlur={() => onBlurHandle('confirmPassword')}
          helperText={errors.confirmPasswordError}
          error={errors.confirmPasswordError !== ''}
          fullWidth
          startAdornment={
            <InputAdornment
              classes={{ positionStart: classes.positionStart }}
              position='start'
            >
              <PasswordIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              classes={{ positionEnd: classes.positionEndRegister }}
              position='end'
            >
              <IconButton
                classes={{ root: classes.iconButton }}
                aria-label='toggle password visibility'
                onClick={(e: any) => handleClickShowConfirmPassword(e)}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                {showConfirmPassword ? (
                  <VisibilityOff style={{ fill: 'black' }} />
                ) : (
                  <Visibility style={{ fill: 'black' }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextArea
          id='info'
          className={`${inputStyle('infoError')}`}
          type='text'
          label='Information'
          name='info'
          placeholder='Write some relevant information about yourself to help the validation process'
          value={form.info}
          setInputValue={handleChange}
          disabled={false}
          required={true}
          multiline={true}
          rows={4}
          autoComplete='given-name'
          onBlur={() => onBlurHandle('info')}
          // helperText={form.errors.firstNameError}
          // error={form.errors.firstNameError !== ''}
        />
      </Grid>
    </>
  );
};

export default TutorForm;

import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = createStyles({
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: THIRD_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
  },
  labelBlack: {
    fontSize: 16,
    fontWeight: 700,
    color: PRIMARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
  },
  autocompleteRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  autocompleteInputAuth: {
    padding: '0 20px 0 10px',
    height: 48,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
    borderRadius: 4,
    border: '1px solid #E0E0E0',
    outline: 0,
    backgroundColor: 'white',
    '&.Mui-focused': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
        '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
        '0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
      '&::placeholder': {
        color: '#7A7A7A',
        opacity: 1,
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: 8,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  autocompleteInputAuthSelect: {
    padding: '0 20px 0 10px',
    minHeight: 48,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
    borderRadius: 4,
    outline: 0,
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    '&.Mui-focused': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
        '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
        '0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
      '&::placeholder': {
        color: '#7A7A7A',
        opacity: 1,
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: 8,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  autocompleteInput: {
    height: 48,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
    borderRadius: 4,
    border: '1px solid #E0E0E0',
    outline: 0,
    '&.Mui-focused': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
        '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
        '0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    '& .MuiAutocomplete-input': {
      padding: '0 16px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: 8,
    },
  },
  chipLabel: {
    backgroundColor: `${PRIMARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
    margin: '12px 12px 12px 0 !important',
    '& .MuiChip-deleteIcon': {
      color: `${THIRD_COLOR} !important`,
    },
  },
});

export default useStyles;

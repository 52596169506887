import { GetLibraryCollectionsResponse, LibraryCollection } from 'mapfilm-api';
import { api } from 'services/customAxios';

const getCollections = async (
  isSeller: boolean
): Promise<GetLibraryCollectionsResponse> => {
  try {
    const url = isSeller ? '/library/collection' : '/catalog/collection';
    const response = await api.get<GetLibraryCollectionsResponse>(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCatalog = async () => {
  try {
    const response = await api.get('/catalog');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCollectionById = async (
  collectionId: string,
  isSeller: boolean
): Promise<LibraryCollection> => {
  try {
    const url = isSeller ? '/library/collection' : '/catalog/collection';
    const response = await api.get<LibraryCollection>(`${url}/${collectionId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { getCatalog, getCollectionById, getCollections };

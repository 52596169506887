import { Grid, MenuItem, Typography } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import TextInput from 'components/controls/textInput/TextInput';
import territoriesDB from 'database/territoriesDB';
import { add, isBefore, isToday } from 'date-fns';
import {
  NonCommercialRightModel,
  PeriodModel,
  RightWindowModel,
  TemplateEducationModel,
} from 'models';
import { EducationalLicenseType, PeriodType } from 'models/enums';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomLicenseActions, LicenseTemplateActions } from 'redux/actions';
import { RootState } from 'redux/store';
import { DatePicker } from 'rsuite';
import { dateToString } from 'utils/validation';

import Styles from 'components/licenses/styles/LicenseStyles';
import DashboardStyles from 'styles/dashboard';
import combineStyles from 'utils/combineStyles';

interface OwnProps {
  classes?: any;
  rightName: string;
  licenseType: string; // template, custom (before was create and customize)
  operationMode: string; // view, edit, create
  license: TemplateEducationModel;
}

interface DispatchProps {
  updateEduTypeLT: (payload: any) => void;
  updateEduInstitutionCL: (payload: any) => void;
  updateEduTypeCL: (payload: any) => void;
  updateEduPeriodCL: (payload: any) => void;
  updateEduPeriodFormatLT: (payload: any) => void;
  updateEduPeriodFormatCL: (payload: any) => void;
  updateEduFieldLT: (payload: any) => void;
}

interface StateProps {
  eduWindow: NonCommercialRightModel;
  eduTemplate: TemplateEducationModel;
  eduCustom: any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  period: PeriodModel;
}

class EducationWindow extends React.Component<Props, State> {
  state = {
    period: {
      start: dateToString(add(new Date(), { days: 1 })),
      end: dateToString(add(new Date(), { days: 1, years: 1 })),
      periodId: '',
    },
  };

  componentDidMount() {
    if (
      this.props.licenseType !== 'custom' ||
      this.props.operationMode !== 'create'
    ) {
      return;
    }

    const eduTemplate = this.props.eduTemplate;
    if (eduTemplate.type !== EducationalLicenseType.BOTH) {
      this.props.updateEduTypeCL({
        rightName: this.props.rightName,
        type: eduTemplate.type,
      });
    }

    if (eduTemplate.periodFormat !== PeriodType.BOTH) {
      this.props.updateEduPeriodFormatCL({
        rightName: this.props.rightName,
        periodFormat: eduTemplate.periodFormat,
      });

      if (eduTemplate.periodFormat === PeriodType.THREE_YEAR) {
        this.setDefaultEndDate(eduTemplate.periodFormat);
      }
    }
  }

  setDefaultEndDate(periodFormat: string) {
    this.props.updateEduPeriodFormatCL({
      rightName: this.props.rightName,
      periodFormat: periodFormat,
    });

    const newPeriod = {
      start: dateToString(add(new Date(), { days: 1 })),
      end: dateToString(add(new Date(), { days: 1, years: 3 })),
      periodId: '',
    };

    if (periodFormat === PeriodType.THREE_YEAR) {
      this.setState({
        period: newPeriod,
      });

      this.props.updateEduPeriodCL({
        rightName: this.props.rightName,
        period: newPeriod,
      });
    }
  }

  updateEndDate = (newFormat: PeriodType) => {
    let end_date: Date = this.parseDate(this.props.eduCustom.period.start);
    let years = 1;
    if (newFormat === PeriodType.THREE_YEAR) years = 3;
    end_date = add(end_date, { years: years });

    this.props.updateEduPeriodCL({
      rightName: this.props.rightName,
      period: {
        start: this.props.eduCustom.period.start,
        end: this.dateToString(end_date),
        periodId: '',
      },
    });
  };

  checkIfSelected = (
    inputType: string,
    inputValue?: PeriodType | EducationalLicenseType
  ) => {
    const eduTemplate = this.props.eduTemplate;
    if (inputType === 'periodFormat')
      return (
        inputValue !== PeriodType.BOTH &&
        inputValue !== eduTemplate.periodFormat
      );
    else if (inputType === 'type')
      return (
        inputValue !== EducationalLicenseType.BOTH &&
        inputValue !== eduTemplate.type
      );
    else return false;
  };

  checkIfDisabled = (
    inputType: string,
    inputValue?: PeriodType | EducationalLicenseType
  ) => {
    const eduTemplate = this.props.eduTemplate;

    if (this.props.licenseType === 'template') return false;

    if (inputType === 'periodFormat')
      return (
        inputValue !== eduTemplate.periodFormat &&
        eduTemplate.periodFormat !== PeriodType.BOTH
      );
    else if (inputType === 'type')
      return (
        inputValue !== eduTemplate.type &&
        eduTemplate.type !== EducationalLicenseType.BOTH
      );
    else return false;
  };

  PeriodFormatSelection = () => {
    const handleChange = (event: any) => {
      if (this.props.licenseType === 'template')
        this.props.updateEduPeriodFormatLT({
          rightName: this.props.rightName,
          periodFormat: event.target.value,
        });
      else if (this.props.licenseType === 'custom') {
        this.props.updateEduPeriodFormatCL({
          rightName: this.props.rightName,
          periodFormat: event.target.value,
        });

        this.updateEndDate(event.target.value);
      }
    };

    const getValue = () => {
      if (this.props.licenseType === 'template')
        return this.props.eduTemplate.periodFormat;
      else if (this.props.licenseType === 'custom')
        return this.props.eduCustom.periodFormat;
    };

    const { classes } = this.props;
    return (
      <div className={classes.eduRightFormControl}>
        <TextInput
          label='Period Format'
          select
          defaultValue={PeriodType.ONE_YEAR}
          pageType='product'
          value={getValue()}
          setInputValue={handleChange}
        >
          <MenuItem className='default-option-hidden' value={''} disabled>
            Select period format
          </MenuItem>
          <MenuItem
            selected={this.checkIfSelected('periodFormat', PeriodType.ONE_YEAR)}
            disabled={this.checkIfDisabled('periodFormat', PeriodType.ONE_YEAR)}
            value={PeriodType.ONE_YEAR}
          >
            One Year
          </MenuItem>
          <MenuItem
            selected={this.checkIfSelected(
              'periodFormat',
              PeriodType.THREE_YEAR
            )}
            disabled={this.checkIfDisabled(
              'periodFormat',
              PeriodType.THREE_YEAR
            )}
            value={PeriodType.THREE_YEAR}
          >
            Three Years
          </MenuItem>
          {this.props.licenseType === 'template' ? (
            <MenuItem
              selected={this.checkIfSelected('periodFormat', PeriodType.BOTH)}
              disabled={this.checkIfDisabled('periodFormat', PeriodType.BOTH)}
              value={PeriodType.BOTH}
            >
              Both
            </MenuItem>
          ) : (
            <></>
          )}
        </TextInput>
      </div>
    );
  };

  TypeSelection = () => {
    const handleChange = (event: any) => {
      if (this.props.licenseType === 'template')
        this.props.updateEduTypeLT({
          rightName: this.props.rightName,
          type: event.target.value,
        });
      else if (this.props.licenseType === 'custom')
        this.props.updateEduTypeCL({
          rightName: this.props.rightName,
          type: event.target.value,
        });
    };

    const getValue = () => {
      if (this.props.licenseType === 'template')
        return this.props.eduTemplate.type;
      else if (this.props.licenseType === 'custom')
        return this.props.eduCustom.type;
    };

    const { classes } = this.props;
    return (
      <div className={classes.eduRightFormControl}>
        <TextInput
          label='Type'
          pageType='product'
          select
          defaultValue={EducationalLicenseType.COURSE}
          value={getValue()}
          setInputValue={handleChange}
        >
          <MenuItem className='default-option-hidden' value={''} disabled>
            Select type
          </MenuItem>
          <MenuItem
            selected={this.checkIfSelected(
              'type',
              EducationalLicenseType.COURSE
            )}
            disabled={this.checkIfDisabled(
              'type',
              EducationalLicenseType.COURSE
            )}
            value={EducationalLicenseType.COURSE}
          >
            Course/students
          </MenuItem>
          <MenuItem
            selected={this.checkIfSelected('type', EducationalLicenseType.PPR)}
            disabled={this.checkIfDisabled('type', EducationalLicenseType.PPR)}
            value={EducationalLicenseType.PPR}
          >
            Public Performance Rights
          </MenuItem>

          {this.props.licenseType === 'template' ? (
            <MenuItem
              selected={this.checkIfSelected(
                'type',
                EducationalLicenseType.BOTH
              )}
              disabled={this.checkIfDisabled(
                'type',
                EducationalLicenseType.BOTH
              )}
              value={EducationalLicenseType.BOTH}
            >
              Both
            </MenuItem>
          ) : (
            <></>
          )}
        </TextInput>
      </div>
    );
  };

  TerritoryPicker = () => {
    return (
      <>
        {/* Territory */}
        <AutocompleteInput
          id='educational-territory'
          pageType=''
          label='Territory'
          name='territory'
          onChange={(event, newValue, reason) => {
            console.log(newValue, reason);
          }}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, value) => option === value}
          options={territoriesDB.countries}
          value='United Kingdom'
          placeholder='Search territory...'
          auth={false}
          required={false}
        />
      </>
    );
  };

  handleInstitutionChange = (event: any) => {
    if (this.props.licenseType === 'custom') {
      this.props.updateEduInstitutionCL({
        rightName: this.props.rightName,
        institution: event.target.value,
      });
    }
  };

  parseDate = (date: string | Date): Date => {
    if (!(date instanceof Date)) return new Date(date.toString());
    else return date;
  };

  dateToString = (date: Date | string): string => {
    if (date instanceof Date) return date.toString();
    else return date;
  };

  CustomDatePicker = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid item sm={6} className={classes.eduElement}>
          <Typography className={classes.territoryTitle} align='left'>
            Start Date
          </Typography>
          <div className={classes.eduRightFormControl}>
            <DatePicker
              className={classes.eduDatePicker}
              ranges={[]}
              disabledDate={(date: any) => {
                // Block dates before today
                const newDate = this.parseDate(date);
                return isBefore(newDate, new Date()) || isToday(newDate);
              }}
              onSelect={(date) => {
                // Add date to local state
                this.setState((prevState) => ({
                  ...prevState,
                  period: {
                    ...prevState.period,
                    start: this.dateToString(date),
                  },
                }));
              }}
              onOk={() => {
                // update redux state
                let end_date: Date = this.parseDate(this.state.period.start);
                let years = this.props.license.duration;
                // Generate end date. start date + (1 or 3) year(s)
                if (this.props.eduCustom.periodFormat === PeriodType.THREE_YEAR)
                  years = 3;
                end_date = add(end_date, { years: years });

                this.props.updateEduPeriodCL({
                  rightName: this.props.rightName,
                  period: {
                    start: this.state.period.start,
                    end: this.dateToString(end_date),
                    periodId: '',
                  },
                });
              }}
              value={this.parseDate(this.props.eduCustom.period.start)}
              onClean={() => {
                // Reset date states
                let years = 1;
                if (this.props.eduCustom.periodFormat === PeriodType.THREE_YEAR)
                  years = 3;
                this.setState((prevState) => ({
                  ...prevState,
                  period: {
                    start: dateToString(add(new Date(), { days: 1 })),
                    end: dateToString(
                      add(new Date(), { days: 1, years: years })
                    ),
                    periodId: '',
                  },
                }));
                this.props.updateEduPeriodCL({
                  rightName: this.props.rightName,
                  period: {
                    start: dateToString(add(new Date(), { days: 1 })),
                    end: dateToString(
                      add(new Date(), { days: 1, years: years })
                    ),
                    periodId: '',
                  },
                });
              }}
            />
          </div>
        </Grid>
        <Grid item sm={6} className={classes.eduElement}>
          <Typography className={classes.territoryTitle} align='left'>
            End Date
          </Typography>
          <div className={classes.eduRightFormControl}>
            <DatePicker
              className={classes.eduDatePicker}
              disabled
              value={this.parseDate(this.props.eduCustom.period.end)}
            />
          </div>
        </Grid>

        {/* End Date: Read only */}
      </>
    );
  };

  CreateModeRender = () => {
    const { classes, licenseType } = this.props;

    let gridSize = 6;
    if (licenseType === 'template') {
      gridSize = 12;
    }

    const PriceSelection = () => {
      const handleChange = (event: any) => {
        if (this.props.licenseType === 'template')
          this.props.updateEduFieldLT({
            rightName: this.props.rightName,
            field: 'price',
            value: parseInt(event.target.value) || -1,
          });
        else if (this.props.licenseType === 'custom')
          this.props.updateEduTypeCL({
            rightName: this.props.rightName,
            type: event.target.value,
          });
      };
      const getValue = () => {
        if (this.props.licenseType === 'template')
          return this.props.eduTemplate.price !== -1
            ? this.props.eduTemplate.price
            : '';
        else if (this.props.licenseType === 'custom')
          return this.props.eduCustom.price !== -1
            ? this.props.eduCustom.price
            : '';
      };
      const { classes } = this.props;
      const customPriceValue = `${
        this.props.license.price
      } ${String.fromCharCode(163)}`;
      return (
        <div className={classes.eduRightFormControl}>
          <TextInput
            label='Price'
            name='price'
            placeholder={'Price in ' + String.fromCharCode(163)}
            value={
              this.props.licenseType === 'custom'
                ? customPriceValue
                : getValue()
            }
            pageType='product'
            setInputValue={handleChange}
            disabled={this.props.licenseType === 'custom' ? true : false}
            required={true}
          />
        </div>
      );
    };

    const DurationSelection = () => {
      const handleChange = (event: any) => {
        if (this.props.licenseType === 'template')
          this.props.updateEduFieldLT({
            rightName: this.props.rightName,
            field: 'duration',
            value: parseInt(event.target.value) || 0,
          });
        else if (this.props.licenseType === 'custom')
          this.props.updateEduTypeCL({
            rightName: this.props.rightName,
            type: event.target.value,
          });
      };

      const getValue = () => {
        if (this.props.licenseType === 'template')
          return this.props.eduTemplate.duration !== 0
            ? this.props.eduTemplate.duration
            : '';
        else if (this.props.licenseType === 'custom')
          return this.props.eduCustom.duration !== 0
            ? this.props.eduCustom.duration
            : '';
      };

      const { classes } = this.props;
      const customDurationValue =
        this.props.license.duration !== 1
          ? this.props.license.duration + ' Years'
          : this.props.license.duration + ' Year';
      return (
        <div className={classes.eduRightFormControl}>
          <TextInput
            label='Duration'
            name='duration'
            placeholder='Years'
            value={
              this.props.licenseType === 'custom'
                ? customDurationValue
                : getValue()
            }
            pageType='product'
            setInputValue={handleChange}
            disabled={this.props.licenseType === 'custom' ? true : false}
            required={true}
          />
        </div>
      );
    };

    const ComponentDatePicker = () => {
      const { classes } = this.props;
      const start = dateToString(new Date());
      const end = dateToString(
        add(new Date(), { years: this.props.license.duration })
      );

      return (
        <>
          <Grid item sm={6} className={classes.eduElement}>
            <Typography className={classes.territoryTitle} align='left'>
              Start Date
            </Typography>
            <div className={classes.eduRightFormControl}>
              <DatePicker
                className={classes.eduDatePicker}
                disabled
                value={this.parseDate(start)}
              />
            </div>
          </Grid>
          <Grid item sm={6} className={classes.eduElement}>
            <Typography className={classes.territoryTitle} align='left'>
              End Date
            </Typography>
            <div className={classes.eduRightFormControl}>
              <DatePicker
                className={classes.eduDatePicker}
                disabled
                value={this.parseDate(end)}
              />
            </div>
          </Grid>

          {/* End Date: Read only */}
        </>
      );
    };

    return (
      <>
        {/* Type */}
        <Grid
          container
          item
          sm={Number(gridSize) as GridSize}
          justifyContent='flex-start'
          className={classes.eduElement}
        >
          <PriceSelection />
        </Grid>

        {/* Period Format */}
        {
          <Grid
            container
            item
            sm={Number(gridSize) as GridSize}
            justifyContent='flex-start'
            className={classes.eduElement}
          >
            <DurationSelection />
          </Grid>
        }

        {this.props.licenseType === 'custom' ? (
          <>
            <ComponentDatePicker />

            {/* Period - begin date (to choose) and end (read only)*/}
            {/*<this.CustomDatePicker />*/}

            {/* Institution */}
            {/*<Grid container item sm={6} justify="flex-start" className={classes.eduElement} >
                    <div className={classes.eduRightFormControl}>
                        <TextInput
                            label="Institution"
                            pageType='product'
                            value={this.props.eduCustom.institutionName}
                            placeholder="Enter the institution name..."
                            setInputValue={this.handleInstitutionChange}
                        />
                    </div>
                </Grid>*/}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  ViewModeRender = () => {
    const { classes } = this.props;

    return (
      <>
        <Grid
          container
          item
          sm={12}
          justifyContent='flex-start'
          className={classes.eduElementView}
        >
          <div className={classes.labelContainer}>
            <Typography className={classes.licenseLabel} component='span'>
              Duration
            </Typography>
            <Typography className={classes.licenseDescription} component='span'>
              {this.props.license.duration}{' '}
              {this.props.license.duration === 1 ? 'years' : 'year'}
            </Typography>
          </div>
        </Grid>

        <Grid
          container
          item
          sm={12}
          justifyContent='flex-start'
          className={classes.eduElementView}
        >
          <div className={classes.labelContainer}>
            <Typography className={classes.licenseLabel} component='span'>
              Price
            </Typography>
            <Typography className={classes.licenseDescription} component='span'>
              {this.props.license.price} {String.fromCharCode(163)}
            </Typography>
          </div>
        </Grid>

        {this.props.licenseType === 'custom' ? (
          <>
            {/* Period - begin date (to choose) and end (read only)*/}
            <Grid
              container
              item
              sm={12}
              justify='flex-start'
              className={classes.eduElement}
            >
              Period: {this.props.eduCustom.period}
            </Grid>

            {/* Institution */}
            <Grid
              container
              item
              sm={12}
              justifyContent='flex-start'
              className={classes.eduElement}
            >
              Institution: {this.props.eduCustom.institutionName}
            </Grid>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  /**
   * Component Render Method
   * @returns
   */
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.educationalContainer}>
          {this.props.operationMode === 'create' ? (
            <this.CreateModeRender />
          ) : (
            <this.ViewModeRender />
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const eduWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as NonCommercialRightModel;

  return {
    eduWindow: eduWindow,
    eduTemplate: state.licenseTemplate.nonCommercialRights[ownProps.rightName],
    eduCustom:
      ownProps.licenseType === 'template'
        ? undefined
        : state.customLicense.nonCommercialRights[ownProps.rightName],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      /* License Template */
      updateEduTypeLT: LicenseTemplateActions.updateEduTypeLT,
      updateEduPeriodFormatLT: LicenseTemplateActions.updateEduPeriodFormatLT,
      updateEduFieldLT: LicenseTemplateActions.updateEduFieldLT,
      /* Custom License */
      updateEduInstitutionCL: CustomLicenseActions.updateEduInstitutionCL,
      updateEduTypeCL: CustomLicenseActions.updateEduTypeCL,
      updateEduPeriodFormatCL: CustomLicenseActions.updateEduPeriodFormatCL,
      updateEduPeriodCL: CustomLicenseActions.updateEduPeriodCL,
    },
    dispatch
  );
};

const combinedStyles = combineStyles(DashboardStyles, Styles);

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combinedStyles as any)(EducationWindow));

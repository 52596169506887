import axios from 'axios';
import settings from 'config';

const protocol = settings.PROTOCOL || 'http';
const expressIP = settings.API_URL || 'localhost';
const expressPort = Number(settings.API_PORT) || 3333;
const apiPath = settings.API_PATH || '';
const baseUrl = `${protocol}://${expressIP}:${expressPort}${apiPath}`;

const api = axios.create({
  baseURL: baseUrl,
});

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${baseUrl}/auth/refreshToken`, {
      refreshToken: refreshToken,
    });
    const newAccessToken = response.data.accessToken;
    localStorage.setItem('accessToken', newAccessToken);
    return newAccessToken;
  } catch (refreshError) {
    console.error('Error refreshing token:', refreshError);
    localStorage.clear();
    throw refreshError;
  }
}

api.interceptors.request.use(async (request) => {
  const accessToken = getAccessToken();
  request.headers['Authorization'] = `Bearer ${accessToken}`;

  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('Error getting response:', error.response);
    if (error.response && error.response.status === 401) {
      // Unauthorized error, attempt to refresh the token
      try {
        const newAccessToken = await refreshAccessToken();
        // Update the original request with the new token and retry
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axios(error.config);
      } catch (refreshError) {
        // Handle refresh token failure
        return Promise.reject(refreshError);
      }
    }
    // For other error responses, simply return the error
    return Promise.reject(error);
  }
);

export { api };

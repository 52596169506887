import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import Loading from 'components/loading/Loading';
import { UserModel } from 'models';
import ProductInfo from 'pages/product/ProductInfo';
import VideoPopup from 'pages/product/ProductVideo';
import { RootState } from 'redux/store';
import { getProduct } from 'services/productService';
import { getUser } from 'services/userService';
import combineStyles from 'utils/combineStyles';
// import { isSeller } from 'services/authService';
import { isBuyer, isSeller } from 'services/authService';
import { getBespokeLicense } from 'services/educationalLicenseService';
import {
  addBFILicenseToCart,
  createTemplateBFI,
} from 'services/licenseService';
// import settings from 'config';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GetUserInfoResponse, LibraryProduct } from 'mapfilm-api';
import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';

const LICENSE_SELLER_ERROR = 'License template already exists.';
const NO_ORG_ERROR =
  'You are not associated to any organization! Please insert your organization in the Profile Page.';
const LICENSE_BUYER_ERROR = 'No license was created for this movie.';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
}

interface State {
  product: LibraryProduct;
  loading: boolean;
  licenseError: string;
  orgError: string;
  templateExists: boolean;
  hasOrg: boolean;
  dialog: boolean;
  dialogTitle: string;
  mediaId: string;
}

interface StateProps {
  user: UserModel;
}

type Props = StateProps & OwnProps;

const queryClient = new QueryClient();

class ProductPage extends React.Component<Props, State> {
  state = {
    product: {
      id: '',
      title: '',
      name: '',
    },
    loading: true,
    licenseError: '',
    orgError: '',
    templateExists: false,
    hasOrg: false,
    dialog: false,
    dialogTitle: '',
    mediaId: '',
  };

  async componentDidMount() {
    const productId = this.props.match.params.id;

    const promiseProduct = getProduct(productId, isBuyer());
    const promiseUser: Promise<GetUserInfoResponse> = getUser();
    const promiseLicense = getBespokeLicense(productId);

    Promise.all([promiseProduct, promiseUser, promiseLicense])
      .then((values) => {
        const product = values[0];
        const userInfo = values[1];
        const templateExists = values[2];
        const hasOrg = userInfo.org ? true : false;

        let licenseError = '';
        let orgError = '';

        if (templateExists) {
          licenseError = isSeller() ? LICENSE_SELLER_ERROR : '';
        } else {
          licenseError = isBuyer() ? LICENSE_BUYER_ERROR : '';
        }

        if (!hasOrg) {
          orgError = NO_ORG_ERROR;
        }

        this.setState({
          product: product,
          hasOrg: hasOrg,
          templateExists: templateExists ? true : false,
          licenseError: licenseError,
          orgError: orgError,
          loading: false,
        });
      })
      .catch((reason: any) => {
        console.error(reason);
      });
  }

  openVideoPopup(dialogValue: boolean) {
    this.setState({
      dialog: dialogValue,
    });
  }

  setVideoInfo(id: string, dialogTitle: string) {
    this.setState({
      mediaId: id,
      dialogTitle: dialogTitle,
    });
  }

  async createLicense() {
    if (this.state.templateExists) {
      this.setState({ licenseError: LICENSE_SELLER_ERROR });
      return;
    }

    this.setState({ loading: true });
    await createTemplateBFI(this.state.product.id.toString()).then(
      (value) => {
        // On run
        this.setState({ loading: false });
        this.props.history.push('/myBespokeLicenses');
        // window.location.reload();
      },
      (reason) => {
        // on fail
        this.setState({ loading: false, licenseError: reason });
        console.log(reason);
      }
    );
  }

  async purchaseLicense() {
    if (!this.state.templateExists) {
      this.setState({ licenseError: LICENSE_BUYER_ERROR });
      return;
    }

    this.setState({ loading: true });
    await addBFILicenseToCart(this.state.product.id.toString()).then(
      (value) => {
        // On run
        this.setState({ loading: false });
        this.props.history.push('/myCustomLicenses');
        // window.location.reload();
      },
      (reason) => {
        // on fail
        this.setState({ loading: false, licenseError: reason });
        console.log(reason);
      }
    );
  }

  render() {
    const { classes } = this.props;
    const { loading, product } = this.state;
    const showError = this.state.licenseError || this.state.orgError;

    if (loading) {
      return <Loading />;
    }

    if (!product.title) {
      return (
        <div className={classes.productNotFound}>
          <Typography variant='h1' className={classes.productNotFoundTitle}>
            Oops...
          </Typography>
          <Typography variant='h2' className={classes.productNotFoundTxt}>
            Film was not found!
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <div className={classes.headerContainer}>
          <Typography variant='h5' className={classes.dashboardSubtitle}>
            {product.title}
          </Typography>
        </div>
        <Typography
          className={classes.licenseError}
          component='h1'
          variant='h6'
          style={{ color: 'red', display: showError ? 'block' : 'none' }}
        >
          {this.state.orgError ? (
            <span className={classes.errorMessage}>
              <ArrowForwardIosIcon /> {this.state.orgError}
            </span>
          ) : (
            <></>
          )}
          {this.state.licenseError ? (
            <span className={classes.errorMessage}>
              <ArrowForwardIosIcon /> {this.state.licenseError}
            </span>
          ) : (
            <></>
          )}
        </Typography>

        <ProductInfo
          product={product}
          openVideoPopup={this.openVideoPopup.bind(this)}
          setVideoInfo={this.setVideoInfo.bind(this)}
        />
        <QueryClientProvider client={queryClient}>
          <VideoPopup
            openPopup={this.state.dialog}
            setOpenPopup={this.openVideoPopup.bind(this)}
            dialogTitle={this.state.dialogTitle}
            id={this.state.mediaId}
          />
        </QueryClientProvider>

        <div className={classes.productOptions}>
          <NavLink className={classes.dashLinkBtn} to={'/catalogue'}>
            <Button className={classes.secondaryBtn} variant='outlined'>
              {isSeller() ? 'Back to My Products' : 'Back to Products'}
            </Button>
          </NavLink>

          {isSeller() ? (
            <>
              <Button
                className={classes.dashboardButton}
                variant='outlined'
                disabled={this.state.templateExists || !this.state.hasOrg}
              >
                {this.state.templateExists || !this.state.hasOrg ? (
                  'Create License'
                ) : (
                  <NavLink
                    className={classes.dashLinkBtn}
                    to={'/movies/' + product.id + '/createLicense'}
                  >
                    Create License
                  </NavLink>
                )}
              </Button>
            </>
          ) : (
            ''
          )}

          {isBuyer() ? (
            <>
              <Button
                className={classes.dashboardButton}
                variant='outlined'
                disabled={!this.state.templateExists || !this.state.hasOrg}
              >
                {!this.state.templateExists || !this.state.hasOrg ? (
                  'Customize License'
                ) : (
                  <NavLink
                    className={classes.dashLinkBtn}
                    to={'/movies/' + product.id + '/customizeLicense'}
                  >
                    Customize License
                  </NavLink>
                )}
              </Button>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(combinedStyles)(ProductPage));

import clsx from 'clsx';
import { useState } from 'react';
// import { useImage } from 'react-image';
import { withStyles } from '@material-ui/styles';
import { Img } from 'react-image';

import combineStyles from 'utils/combineStyles';

import DefaultPoster from 'assets/images/default-image.jpg';
// import EditIcon from 'assets/images/edit-icon.svg';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

interface Props {
  classes?: any;
  movie: boolean;
  title: string;
  poster?: string;
  director?: string;
  synopsis?: string;
  year?: number;
  duration?: number;
  genres?: any;
  genreList?: any;
}

interface Genre {
  id: string;
  name: string;
}

function ItemProduct(props: Props) {
  const {
    classes,
    poster,
    title,
    director,
    movie,
    synopsis,
    genres,
    year,
    duration,
    genreList,
  } = props;
  const [srcImg] = useState(poster ? poster : DefaultPoster);

  const getGenreNamesByIds = (
    genreIds: string[],
    genreList: Genre[]
  ): (string | null)[] => {
    const genreNames = genreIds.map((id) => {
      const genre = genreList.find((item) => item.id === id);
      return genre ? genre.name : null;
    });

    return genreNames.slice(0, 3);
  };

  return (
    <Card className={classes.cardStyles}>
      <CardMedia
        component='div'
        sx={{ position: 'relative', width: 151, padding: '28px' }}
      >
        <div className={clsx(classes.imageHover, 'movie-image-hover')}>
          <MovieOutlinedIcon />
        </div>
        <Img
          src={[srcImg, DefaultPoster]}
          className={movie ? classes.productImage : classes.collectionImage}
        />
      </CardMedia>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <div className={classes.productHeader}>
            <div className={classes.productTitle}>{title}</div>
            <div className={classes.productGenre}>
              {genres
                ? getGenreNamesByIds(genres, genreList).map((genre, index) => (
                    <span key={index} className={classes.genreItem}>
                      {genre}
                    </span>
                  ))
                : ''}
            </div>
          </div>
          <div className={classes.productDirector}>{director}</div>
          <div className={classes.productDirector}>
            {year && duration
              ? `${year} | ${duration} min`
              : year
              ? year
              : duration
              ? duration + ' min'
              : ''}
          </div>
          <div className={classes.productSinopsis}>{synopsis}</div>
        </CardContent>
      </Box>
    </Card>
  );
}

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles)(ItemProduct);

import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import TextInput from 'components/controls/textInput/TextInput';
import TextArea from 'components/controls/textarea/TextArea';
import FieldData from 'components/fielddata/FieldData';
import Genres from 'pages/product/ProductGenres';
import combineStyles from 'utils/combineStyles';
import { removeHTML } from 'utils/utils';

import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';

import { project } from 'project';

import { THIRD_COLOR } from 'styles/colors';

//import MovieIcon from '@material-ui/icons/Movie';
import MovieIcon from '@mui/icons-material/Movie';
import DefaultPoster from 'assets/images/default-img.jpg';
import { LibraryProduct } from 'mapfilm-api';

interface Props {
  classes: any;
  product: LibraryProduct;
  openVideoPopup: (value: boolean) => void;
  setVideoInfo: (id: string, dialogTitle: string) => void;
}

class ProductInfo extends React.Component<Props> {
  handleVersionSelect(version: any) {
    this.props.setVideoInfo(version._id, version.name);
    this.props.openVideoPopup(true);
  }

  render() {
    const { classes, product } = this.props;
    if (!product.genres) {
      product.genres = [];
    }
    const year = product.year;
    // const duration = formatSecondsToTime(product.duration);
    const duration = product.duration;
    const poster = product.posterUrl ?? DefaultPoster;
    const synopsis = product.synopsis
      ? removeHTML(product.synopsis).replace(/$(\r|\n)(?=.)/gm, ' ')
      : product.synopsis;

    console.log('Product', product);

    return (
      <>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item container sm={4} alignItems='flex-start'>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.moviePosterContainer}>
                <img
                  className={classes.moviePoster}
                  src={poster}
                  alt='Film Poster'
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.movieOptions}>
                  {product.versions &&
                    product.versions.map((version: any) => (
                      <Button
                        key={version}
                        className={classes.secondaryBtn}
                        variant='outlined'
                        onClick={() => this.handleVersionSelect(version)}
                      >
                        <MovieIcon
                          sx={{ color: THIRD_COLOR, marginRight: '10px' }}
                        />
                        <span className={classes.optionDescription}>
                          {version.name}
                        </span>
                      </Button>
                    ))}
                </div>
              </Grid>
              {product.language && project !== 'mfh' ? (
                <Grid item xs={12}>
                  <TextInput
                    type='text'
                    label='Language'
                    pageType='product'
                    placeholder='Enter the film language'
                    value={product.language}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {product.regions && project === 'mfh' ? (
                <Grid item sm={12}>
                  <FieldData label='Regions' description='' />
                  <Genres
                    genres={product.regions.map((region: any) => region) ?? []}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {/* Right Column */}
          <Grid item container sm={8} alignItems='flex-start'>
            <Grid item container spacing={3}>
              <Grid item sm={6}>
                <TextInput
                  type='text'
                  label='Title'
                  pageType='product'
                  placeholder='Enter the film title'
                  value={product.title}
                  disabled={true}
                  required={true}
                />
              </Grid>

              {product.director ? (
                <Grid item sm={6}>
                  <TextInput
                    type='text'
                    label='Director'
                    placeholder='Enter the director name'
                    pageType='product'
                    value={product.director}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {product.year ? (
                <Grid item sm={product.director ? 4 : 6}>
                  <TextInput
                    type='number'
                    label='Year'
                    pageType='product'
                    placeholder='Enter the film year'
                    value={year ? year : -1}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {product.contentRating && project !== 'mfh' ? (
                <Grid item sm={product.director ? 4 : 6}>
                  <TextInput
                    type='text'
                    label='Content rating'
                    pageType='product'
                    placeholder='Select the content rating'
                    value={product.contentRating}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {product.date && project === 'mfh' ? (
                <Grid item sm={product.director ? 4 : 6}>
                  <TextInput
                    type='text'
                    label='Copyright date'
                    pageType='product'
                    placeholder='Enter the copyright date'
                    value={product.date}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid item sm={product.director ? 4 : 6}>
                <TextInput
                  type='text'
                  label='Duration'
                  pageType='product'
                  placeholder='Select the duration'
                  value={duration}
                  disabled={true}
                  required={true}
                />
              </Grid>

              <Grid item sm={12}>
                <FieldData label='Themes' description='' />
                <Genres
                  genres={product.genres.map((genre: any) => genre.name) ?? []}
                />
              </Grid>

              {product.synopsis ? (
                <Grid item sm={12}>
                  <TextArea
                    type='text'
                    label='Synopsis'
                    pageType='product'
                    placeholder='Enter the description of the film'
                    value={synopsis}
                    multiline={true}
                    rows={5}
                    disabled={true}
                    required={true}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(ProductInfo);

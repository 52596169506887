import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import { VideoPlayer } from '@videojs-player/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { isBuyer } from 'services/authService';
import { watchProductMedia } from 'services/productService';
import DashboardStyles from 'styles/dashboard';
import combineStyles from 'utils/combineStyles';
import 'video.js/dist/video-js.css';
import MovieStyles from './PStyles';

interface OwnProps {
  classes?: any;
  id: string;
  openPopup: boolean;
  dialogTitle: string;
  setOpenPopup: (value: boolean) => void | undefined;
}

function VideoPopup(props: OwnProps) {
  const { classes, openPopup, id, setOpenPopup } = props;

  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(openPopup);

  const {
    data: videoData,
    refetch,
    isLoading,
  } = useQuery(
    'watchVideo',
    async () => {
      return await watchProductMedia(id, isBuyer());
    },
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (openPopup && videoData) {
      setVideoUrl(videoData.url);
      refetch();
      setIsVisible(true);
    } else {
      setVideoUrl(null);
      setIsVisible(false);
    }
  }, [openPopup, videoData]);

  useEffect(() => {
    if (!openPopup) {
      setVideoLoaded(false); // Reset video loaded state when closing the popup
    }
  }, [openPopup]);

  const onClose = () => {
    setOpenPopup(false);
    setIsVisible(false); // Hide the popup after closing
  };

  // Check if there is no video available and prevent rendering the popup
  if (!isVisible) {
    return null;
  }

  return (
    <Dialog open={openPopup} onClose={onClose} maxWidth='xl'>
      <DialogContent className={classes.popupContainer}>
        <div className={classes.videoHeader}>
          <Typography variant='h5' className={classes.dashboardSubtitleBlue}>
            {props.dialogTitle}
          </Typography>

          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.videoContainer}>
          {videoUrl && (
            <VideoPlayer
              className={`video-js vjs-big-play-centered ${
                isVideoLoaded ? '' : 'hidden'
              }`}
              controls
              poster={videoData?.poster}
              sources={[
                {
                  src: videoUrl,
                  type: videoData?.mime || '',
                },
              ]}
              crossOrigin='*'
              onReady={() => setVideoLoaded(true)}
            />
          )}
        </div>

        <div className={classes.productOptions}>
          <Button
            className={classes.dashboardButton}
            variant='outlined'
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(VideoPopup);

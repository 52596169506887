import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link as NavLink } from 'react-router-dom';

import { AuthenticatedLink } from 'components/authenticatedLink/authenticatedLink';
import Loading from 'components/loading/Loading';
import { isBuyer, isSeller } from 'services/authService';
import { DownloadVideo } from 'services/cartService';
import {
  DownloadContract,
  getSignedLicenses,
} from 'services/educationalLicenseService';
import combineStyles from 'utils/combineStyles';
//import { LicenseTemplateModel } from 'models';

import { ReactComponent as DownloadIcon } from 'assets/images/download-icon.svg';
import { UserModel } from 'models';
import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';

interface OwnProps {
  classes: any;
  history: any;
}

interface State {
  licenses: any[];
  loading: boolean;
}

interface StateProps {
  user: UserModel;
}

type Props = OwnProps & StateProps;

class CLlist extends React.Component<Props, State> {
  state = {
    licenses: [],
    loading: true,
  };

  async componentDidMount() {
    await getSignedLicenses().then(
      (value) => {
        // On run
        this.setState({
          licenses: value,
          loading: false,
        });
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <Typography className={classes.dashboardTitle} variant='h4'>
            My Signed Licenses
          </Typography>
        </div>

        {this.state.loading ? (
          <Loading />
        ) : (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Film Title</TableCell>
                  {isBuyer() ? <TableCell>Film File</TableCell> : <></>}
                  {isBuyer() ? <TableCell>Licensor</TableCell> : <></>}
                  {isSeller() ? <TableCell>Licensee</TableCell> : <></>}
                  {isBuyer() ? <TableCell>Creation Date</TableCell> : <></>}
                  {isSeller() ? <TableCell>Start Date</TableCell> : <></>}
                  {/* {isSeller() ? <TableCell>Duration (Years)</TableCell> : <></>} */}
                  <TableCell>Contract</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {this.state.licenses.length === 0 ? (
                  <TableRow>
                    <TableCell className={classes.noLicenses} colSpan={5}>
                      No entries were found.
                    </TableCell>
                  </TableRow>
                ) : (
                  this.state.licenses.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        <Link
                          className={classes.downloadLink}
                          component={NavLink}
                          to={`/movies/${row.TitleID}`}
                        >
                          {row.FilmTitle}
                        </Link>
                      </TableCell>

                      {isBuyer() ? (
                        <TableCell>
                          <Link
                            className={classes.downloadLink}
                            href={DownloadVideo(row.TitleID)}
                          >
                            <DownloadIcon className={classes.downloadIcon} />
                            Download
                          </Link>
                        </TableCell>
                      ) : (
                        <></>
                      )}

                      {isBuyer() ? (
                        <TableCell>{row.Licensor}</TableCell>
                      ) : (
                        <></>
                      )}

                      {isSeller() ? (
                        <TableCell>{row.Licensee}</TableCell>
                      ) : (
                        <></>
                      )}

                      {isBuyer() ? (
                        <TableCell>
                          {moment(new Date(row.CreationDate)).format(
                            'DD-MMM-YYYY'
                          )}
                        </TableCell>
                      ) : (
                        <></>
                      )}

                      {isSeller() ? (
                        <TableCell>
                          {moment(row.StartDate, 'DD-MMM-YYYY').format(
                            'DD-MMM-YYYY'
                          )}
                        </TableCell>
                      ) : (
                        <></>
                      )}

                      {/* {isSeller() ? (
                        <TableCell>{row.LicensingPeriod}</TableCell>
                      ) : (
                        <></>
                      )} */}

                      <TableCell>
                        <AuthenticatedLink
                          className={classes.downloadLink}
                          url={DownloadContract(row.LicenseID)}
                          filename={`Contract-${row.FilmTitle.replace(
                            /[ ]+/g,
                            '-'
                          )}.pdf`}
                        >
                          <DownloadIcon className={classes.downloadIcon} />
                          Download
                        </AuthenticatedLink>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  user: state.user,
});

const combinedStyles = combineStyles(DashStyles, Styles);

export default connect(mapStateToProps)(
  withStyles(combinedStyles as any)(CLlist)
);

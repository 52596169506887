import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, TextField, Typography } from '@mui/material';
import BackgroundIcon from 'assets/images/path7732.svg';
import { GetUserInfoResponse } from 'mapfilm-api';
import { UserModel } from 'models';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { isSeller } from 'services/authService';
import { getAllProducts } from 'services/productService';
import { getUser } from 'services/userService';
import { PRIMARY_COLOR } from 'styles/colors';
import {
  BottomContainer,
  ExternalContainer,
  TopContainer,
} from 'styles/profile';

interface OwnProps {
  classes?: any;
  history: any;
}

interface StateProps {
  user: UserModel;
}
export default function Profile() {
  const [form, setForm] = useState({
    orgName: '',
    orgLegalName: '',
    orgAddress: '',
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    passwordError: '',
    confirmPasswordError: '',
  });

  const [loading, setLoading] = useState(true);
  const [formSuccessMessage, setFormSuccessMessage] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [userData, setUserData] = useState({} as GetUserInfoResponse);
  const [filmCounter, setFilmCounter] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const getUserData = async () => {
    const data = await getUser();
    setUserData(data);
  };

  useEffect(() => {
    getUserData();
    getAllProducts(isSeller()).then(
      (response) => {
        setFilmCounter(response.products.length);
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
  }, []);

  useEffect(() => {
    console.log(filmCounter);
  }, [filmCounter]);

  function capitalizeFirstLetter(string: string | undefined) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  return (
    <Box sx={ExternalContainer}>
      <Box sx={ExternalContainer.background}>
        <img src={BackgroundIcon} alt='background-icon' />
      </Box>
      <Box sx={TopContainer}>
        <Box sx={TopContainer.leftSide}>
          <Box sx={TopContainer.image}>
            <Avatar
              color={PRIMARY_COLOR}
              name={`${userData.firstname} ${userData.lastname}`}
              round='50%'
              size='100'
            />
          </Box>
          <Box sx={TopContainer.userInformation}>
            <Typography variant='h3'>
              {userData?.firstname + ' ' + userData?.lastname}
            </Typography>
            <Typography variant='h4'>{'@' + userData?.username}</Typography>
          </Box>
        </Box>
        <Box sx={TopContainer.rightSide}>
          <Box sx={TopContainer.filmCounter}>
            <Box sx={TopContainer.filmCounter.text}>
              <Typography variant='h3'>
                {filmCounter ? filmCounter : 0}
              </Typography>
              <Typography variant='h4'>Films saved</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={BottomContainer}>
        <Box sx={BottomContainer.container}>
          <Box sx={BottomContainer.header}>
            <Box sx={BottomContainer.title}>
              <Typography variant='h4'>Personal information</Typography>
            </Box>
            <Box sx={BottomContainer.icon}>
              <EditIcon onClick={() => setEditMode(!editMode)} />
            </Box>
          </Box>
          <Box sx={BottomContainer.information}>
            <Box sx={BottomContainer.dataRow}>
              <Box sx={BottomContainer.dataRow.leftSide}>
                <Typography>Email</Typography>
              </Box>
              <Box sx={BottomContainer.dataRow.rightSide}>
                {!editMode ? (
                  <Typography>{userData?.email}</Typography>
                ) : (
                  <TextField
                    sx={{ width: '70%' }}
                    id='standard-multiline-flexible'
                    value={userData?.email}
                    label='Edit'
                    multiline
                    maxRows={4}
                    variant='standard'
                  />
                )}
              </Box>
            </Box>
            <Box sx={BottomContainer.dataRow}>
              <Box sx={BottomContainer.dataRow.leftSide}>
                <Typography>Role</Typography>
              </Box>
              <Box sx={BottomContainer.dataRow.rightSide}>
                {!editMode ? (
                  <Typography>
                    {capitalizeFirstLetter(userData?.org?.role)}
                  </Typography>
                ) : (
                  <TextField
                    sx={{ width: '70%' }}
                    id='standard-multiline-flexible'
                    value={capitalizeFirstLetter(userData?.org?.role)}
                    label='Edit'
                    multiline
                    maxRows={4}
                    variant='standard'
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={BottomContainer.footer}>
            <Box sx={BottomContainer.footer.leftSide}>
              <Button variant='contained' disableElevation>
                Change password
              </Button>
            </Box>
            <Box sx={BottomContainer.footer.rightSide}>
              {editMode ? (
                <>
                  <Button
                    variant='contained'
                    disableElevation
                    sx={{
                      backgroundColor: PRIMARY_COLOR,
                      marginRight: '5px',
                      '&:hover': { backgroundColor: 'grey' },
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => setEditMode(false)}
                    disableElevation
                    sx={{ '&:hover': { backgroundColor: 'grey' } }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

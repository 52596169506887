import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// import Loading from 'components/loading/Loading';
import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import { LicenseTemplateModel } from 'models';
import { RootState } from 'redux/store';
import { CreateLicense } from 'services/educationalLicenseService';
import { getProduct } from 'services/productService';
import { getUser } from 'services/userService';

import { isBuyer } from 'services/authService';
import Styles from 'styles/dashboard';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
}

interface State {
  loading: boolean;
  disabledBtn: boolean;
  productTitle: string;
  licenseError: string;
  hasOrg: boolean;
}

interface StateProps {
  licenseTemplate: LicenseTemplateModel;
}

type Props = StateProps & OwnProps;

class CreateLTemplatePage extends React.Component<Props, State> {
  state = {
    loading: true,
    disabledBtn: true,
    productTitle: '',
    licenseError: '',
    hasOrg: false,
  };

  async componentDidMount() {
    /* Runs after the render */
    await getProduct(this.props.match.params.id, isBuyer()).then(
      (value) => {
        // On run
        this.setState({ productTitle: value.title });
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
    await getUser().then((user) => {
      // On run

      if (user.org) {
        this.setState((prevState) => ({
          ...prevState,
          hasOrg: true,
        }));
      }
    });
  }

  createLicenseTemplate = async () => {
    const productId = this.props.match.params.id;
    const duration =
      this.props.licenseTemplate.nonCommercialRights.Educational.duration;
    const price =
      this.props.licenseTemplate.nonCommercialRights.Educational.price;
    this.setState({ loading: true });

    await CreateLicense(productId, duration, price).then(
      (value) => {
        // On run
        console.log(value);
        this.setState({ loading: false });
        this.props.history.push('/myBespokeLicenses');
        // window.location.reload();
      },
      (reason) => {
        // on fail
        console.log(reason);
      }
    );
  };

  setDisabledState = (disabled: boolean) => {
    if (this.state.disabledBtn !== disabled) {
      this.setState({ disabledBtn: disabled });
    }
  };

  setLoadingState = (loading: boolean) => {
    this.setState({ loading: loading });
  };

  render() {
    const { classes } = this.props;
    const productId = this.props.match.params.id;
    return (
      <div>
        <div className={classes.headerWithoutMargin}>
          <Typography variant='h5' className={classes.dashboardSubtitleBlue}>
            Create License
          </Typography>
        </div>

        {/*this.state.loading ?
                    <Loading /> : <></>*/}

        {/*this.state.loading ? <></> :*/}
        <Grid container>
          <Grid item xs={12} md={12}>
            {this.state.hasOrg ? (
              <LicenseWindow
                licenseType='template'
                productId={productId}
                licenseId=''
                operationMode='create'
                setDisabledState={this.setDisabledState.bind(this)}
                loading={this.state.loading}
                setLoadingState={this.setLoadingState.bind(this)}
              />
            ) : (
              <></>
            )}
            <div className={classes.footerContainer}>
              <NavLink
                className={classes.dashLinkBtn}
                to={`/movies/${productId}`}
              >
                <Button className={classes.secondaryBtn} variant='outlined'>
                  Back to Film
                </Button>
              </NavLink>

              <Button
                className={classes.dashboardButton}
                variant='outlined'
                disableRipple
                disabled={this.state.hasOrg ? this.state.disabledBtn : true}
                onClick={() => {
                  this.createLicenseTemplate();
                }}
              >
                Save Template
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  licenseTemplate: state.licenseTemplate,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(Styles as any)(CreateLTemplatePage));

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ClausesWindowBody from 'components/licenses/forms/windows/ClausesWindowBody';
import {
  CommercialRightModel,
  CustomLicenseModel,
  RightWindowModel,
} from 'models';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import DoneIcon from '@material-ui/icons/Done';
import Styles from 'components/licenses/styles/LicenseStyles';

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  licenseType: string;
  operationMode: string;
}

interface DispatchProps {
  updateClausesCurrentOpenTabLW: (payload: any) => void;
  addClausesTabLW: (payload: any) => void;
  removeFromClausesLeftToBeChosenLW: (payload: any) => void;
  updateClausesLeftToBeChosenLW: (payload: any) => void;

  removeClauseEntryLW: (payload: any) => void;
  removeClauseEntryLT: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  subRigntWindow: any;
  subRightCustom: any;
  subRightTemplate: any;
  customLicense: CustomLicenseModel;
  licenseTemplate: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class ClausesWindow extends React.Component<Props> {
  componentDidMount() {
    const clauses = this.getAllowedClauses();

    this.props.updateClausesLeftToBeChosenLW({
      rightName: this.props.rightName,
      subRightName: this.props.subRightName,
      leftToBeChosen: clauses,
    });

    this.setState({ clauses: clauses });
  }

  getAllowedClauses = () => {
    const clauses = this.props.subRightTemplate.clauses;
    const keys = Object.keys(this.props.subRightTemplate.clauses);

    const allowedClauses = [];

    for (let i = 0; i < keys.length; i++) {
      if (clauses[keys[i]].allowed) allowedClauses.push(keys[i]);
    }

    if (this.props.operationMode === 'create') {
      if (this.props.licenseType === 'custom') {
        return allowedClauses;
      }
      // template form
      else return keys;
    } else {
      // View mode
      return allowedClauses;
    }
  };

  // Updates currently selected clause
  selectClause = (tab: string) => {
    const {
      subRigntWindow,
      subRightTemplate,
      rightName,
      subRightName,
      addClausesTabLW,
      updateClausesCurrentOpenTabLW,
      removeFromClausesLeftToBeChosenLW,
    } = this.props;

    if (subRigntWindow.currentOpenTab !== tab) {
      if (subRigntWindow.currentOpenTab !== 'Add Clause') {
        this.resetClause(subRigntWindow.currentOpenTab);
      }

      updateClausesCurrentOpenTabLW({
        newTab: tab,
        rightName: rightName,
        subRightName: subRightName,
      });

      removeFromClausesLeftToBeChosenLW({
        newTab: tab,
        rightName: rightName,
        subRightName: subRightName,
      });

      if (this.props.licenseType === 'template')
        addClausesTabLW({
          newTab: tab,
          rightName: rightName,
          subRightName: subRightName,
          subRightTemplate: null,
        });

      if (this.props.licenseType === 'custom')
        addClausesTabLW({
          newTab: tab,
          rightName: rightName,
          subRightName: subRightName,
          subRightTemplate: subRightTemplate,
        });
    }
  };

  // Reset clause when selected option changes
  resetClause(clauseName: string) {
    const {
      operationMode,
      licenseType,
      subRightTemplate,
      subRightCustom,
      rightName,
      subRightName,
      removeClauseEntryLW,
      removeClauseEntryLT,
      removeClauseEntryCL,
    } = this.props;

    const payload = {
      rightName: rightName,
      subRightName: subRightName,
      clauseName: clauseName,
    };

    removeClauseEntryLW(payload);

    // License Templates: If right tree was saved, DO NOT reset clauses data
    if (licenseType === 'template') {
      const clause = subRightTemplate.clauses[clauseName];
      if (clause.allowed) {
        return;
      }
    }

    // Custom Licenses: If right tree was saved, DO NOT reset clauses data
    if (licenseType === 'custom') {
      const clause = subRightCustom[clauseName];
      if (clause.saved) {
        return;
      }
    }

    if (operationMode === 'create') {
      if (licenseType === 'template')
        removeClauseEntryLT({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          licenseType: licenseType,
          operationMode: operationMode,
        });

      if (licenseType === 'custom') removeClauseEntryCL(payload);
    }
  }

  /**
   * Function to return the style of clause option
   * If clause was previously saved -> savedButton
   * If clause was selected by user -> activeButton
   */
  selectedClauseStyle = (item: string) => {
    const {
      classes,
      licenseType,
      subRigntWindow,
      subRightTemplate,
      subRightCustom,
    } = this.props;

    const clauses = subRightTemplate.clauses;
    if (licenseType === 'template' && clauses[item]?.allowed) {
      return classes.savedButton;
    } else if (licenseType === 'custom' && subRightCustom[item]?.saved) {
      return classes.savedButton;
    }

    return subRigntWindow.currentOpenTab === item ? classes.activeButton : '';
  };

  // Function to check if tree path was previously saved
  checkIfSaved = (item: string) => {
    const { licenseType, subRightTemplate, subRightCustom } = this.props;
    const clauses = subRightTemplate.clauses;

    if (licenseType === 'template' && clauses[item]?.allowed) {
      return true;
    } else if (licenseType === 'custom' && subRightCustom[item]?.saved) {
      return true;
    }
    return false;
  };

  // Clauses header: Render clauses options
  ClausesHeader = () => {
    const { classes } = this.props;
    const clauses = this.getAllowedClauses();

    return (
      <div>
        <div className={clsx(classes.licenseSubtitle, classes.titleMargin)}>
          Choose Clauses
        </div>

        <div className={classes.optionsContainer}>
          {/* Normal Tab Buttons */}
          {clauses.map((item: any, index: any) => {
            return (
              <Button
                key={index}
                className={clsx(
                  classes.licenseButton,
                  this.selectedClauseStyle(item)
                )}
                onClick={() => {
                  this.selectClause(item);
                }}
              >
                {item}
                {this.checkIfSaved(item) ? (
                  <DoneIcon className={classes.doneIcon} />
                ) : (
                  <></>
                )}
              </Button>
            );
          })}
        </div>
      </div>
    );
  };

  // Clauses body: Render territories
  ClausesBody = () => {
    const { rightName, subRightName, subRigntWindow } = this.props;
    const clauses = this.getAllowedClauses();

    let currentBody = <></>;

    if (clauses.indexOf(subRigntWindow.currentOpenTab) !== -1)
      currentBody = (
        <>
          <ClausesWindowBody
            rightName={rightName}
            subRightName={subRightName}
            clauseName={subRigntWindow.currentOpenTab}
            licenseType={this.props.licenseType}
            operationMode={this.props.operationMode}
          />
        </>
      );

    return <div>{currentBody}</div>;
  };

  render() {
    return (
      <>
        <div>
          <this.ClausesHeader />
          <this.ClausesBody />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const rightWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as CommercialRightModel;
  const subrightWindow =
    rightWindow !== undefined ? rightWindow[ownProps.subRightName] : null;

  return {
    customLicense: state.customLicense,
    licenseTemplate: state.licenseTemplate,
    subRigntWindow: subrightWindow,
    subRightTemplate:
      state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
        ownProps.subRightName
      ],
    subRightCustom:
      state.customLicense.commercialRights[ownProps.rightName][
        ownProps.subRightName
      ],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateClausesCurrentOpenTabLW:
        LicenseWindowActions.updateClausesCurrentOpenTabLW,
      addClausesTabLW: LicenseWindowActions.addClausesTabLW,
      removeFromClausesLeftToBeChosenLW:
        LicenseWindowActions.removeFromClausesLeftToBeChosenLW,
      updateClausesLeftToBeChosenLW:
        LicenseWindowActions.updateClausesLeftToBeChosenLW,
      removeClauseEntryLW: LicenseWindowActions.removeClauseEntryLW,

      removeClauseEntryLT: LicenseTemplateActions.removeClauseEntryLT,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles as any)(ClausesWindow));

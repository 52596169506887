import { FormLabel, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DropzoneVideos from 'components/controls/dropzone/VideoDropzone';
import TextInput from 'components/controls/textInput/TextInput';
import React from 'react';
import { useIsMutating, useMutation } from 'react-query';

import i1 from 'assets/icons/icon1.svg';
import i2 from 'assets/icons/icon2.svg';
import i3 from 'assets/icons/icon3.svg';

import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import { ProductVersionForm } from 'models/ProductsModel';
import { createProductMedia } from 'services/productService';
import Styles from 'styles/dashboard';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from 'assets/icons/checkIcon.svg';
import ErrorIcon from 'assets/icons/errorIcon.svg';

import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

interface MediaFormProps {
  formData: ProductVersionForm;
  index: number;
  classes: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: (index: number, uuid: string) => void;
  handleRemoveForm: any;
}

const MediaForm: React.FC<MediaFormProps> = ({
  formData,
  index,
  classes,
  handleInputChange,
  handleDropChange,
  handleFilmVersionAdd,
  handleRemoveForm,
}) => {
  function FileUploadQuery({ formData }: { formData: ProductVersionForm }) {
    return <FileUploadIconRender formData={formData} index={index} />;
  }

  function FileUploadIconRender({
    formData,
    index,
  }: {
    formData: ProductVersionForm;
    index: number;
  }) {
    const mutation = useMutation(
      async (data: ProductVersionForm) => {
        if (!data.video) {
          throw new Error('No file');
        }
        return createProductMedia(data.version, data.video);
      },
      { mutationKey: 'createProductMedia' }
    );

    const isMutation = useIsMutating({
      mutationKey: 'createProductMedia',
      exact: true,
    });

    console.log('dataaaa', formData);

    React.useEffect(() => {
      if (mutation.isSuccess) {
        const uuid = mutation.data; // Assuming the mutation result is the UUID
        handleFilmVersionAdd(index, uuid);
      }
    }, [mutation.isSuccess, mutation.data, handleFilmVersionAdd, index]);

    if (mutation.data) {
      // return <CheckCircleIcon sx={{ color: '#D8996F' }} />;
    }

    return (
      <>
        <button
          className={
            !formData.video
              ? classes.disButtonUploadVid
              : classes.buttonUploadVid
          }
          type='submit'
          onClick={() => mutation.mutate(formData)}
          disabled={!formData.video || formData.version === ''}
        >
          {mutation.isLoading ? (
            <Box sx={{ display: 'inline' }}>
              <CircularProgress
                sx={{
                  color: THIRD_COLOR,
                  width: '25px !important',
                  height: '25px !important',
                }}
              />
            </Box>
          ) : mutation.isError ? (
            <img src={ErrorIcon} />
          ) : formData.status === 'UPLOADING' ? (
            <img src={CheckIcon} />
          ) : (
            <>
              Upload
              <IconButton
                sx={{
                  zIndex: '1',
                }}
                disabled={!formData.video || formData.version === ''}
              >
                <PublishOutlinedIcon sx={{ color: THIRD_COLOR }} />
              </IconButton>
            </>
          )}
        </button>
      </>
    );
  }

  return (
    <div key={index}>
      <div className={classes.addMediaBody}>
        <div className={classes.iconContainer}>
          <IconButton
            sx={{
              zIndex: '2',
            }}
            onClick={() => handleRemoveForm(index)}
          >
            <CloseIcon sx={{ color: PRIMARY_COLOR }} />
          </IconButton>
        </div>
        <Grid container>
          {/* Row 1 */}
          <Grid item xs={12} container className={classes.labelRow}>
            <Grid item xs={4} className={classes.firstColumn}>
              <img src={i1} className={classes.numberIcons} />
              <FormLabel className={classes.labelBlack} component='legend'>
                Name
              </FormLabel>
            </Grid>
            <Grid item xs={4} className={classes.secColumn}>
              <img src={i2} className={classes.numberIcons} />
              <FormLabel className={classes.labelBlack} component='legend'>
                Choose Video
              </FormLabel>
            </Grid>
            <Grid item xs={4} className={classes.thirdColumn}>
              <img src={i3} className={classes.numberIcons} />
              <FormLabel className={classes.labelBlack} component='legend'>
                Upload Video
              </FormLabel>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} container className={classes.inputRow}>
            <Grid item xs={4} className={classes.firstColumn}>
              <TextInput
                name='trailer'
                type='text'
                // label='Insert name'
                pageType='product'
                placeholder='Insert name'
                disabled={formData.uuid ? true : false}
                value={formData.version}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, 'version', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} className={classes.secColumn}>
              <DropzoneVideos
                setPropVideo={(acceptedFiles) =>
                  handleDropChange(index, 'video', acceptedFiles)
                }
                propVideo={formData.video}
              />
            </Grid>
            <Grid item xs={4} className={classes.thirdColumn}>
              <FileUploadQuery formData={formData} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(Styles as any)(MediaForm);

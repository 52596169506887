import { createStyles } from '@material-ui/styles';
import { PRIMARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  dropzoneContainer: {
    width: '100%',
    //minHeight: "200px",
    height: '304px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '10px',
    transition: 'border .24s ease-in-out',
    backgroundColor: PRIMARY_COLOR,
  },
  imgSize: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  centralize: {
    textAlign: 'center',
  },
  reactPlayerContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '25px',
    borderRadius: '5px',
  },
  labelsContainer: {
    width: '100%',
    minHeight: '100px',
    //height: "304px",
    marginTop: '25px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  pContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pStyle: {
    textAlign: 'center',
    color: '#7A7A7A',
    fontFamily: 'Roboto',
    fontSize: 16,
    marginLeft: '13px',
  },
});

export default useStyles;

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { createProduct } from 'services/productService';

import Typography from '@mui/material/Typography';

import AddMedia from 'pages/products/addFilms/AddMedia';
import FilmDetails from 'pages/products/addFilms/FilmDetails';

import lineSplit from 'assets/images/lineSplit.svg';

import { ProductVersionForm, ProductWithPoster } from 'models/ProductsModel';
import Styles from 'styles/dashboard';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
}

interface State {
  product: ProductWithPoster;
  activeStep: number;
  skipped: Set<number>;
  imageURL: string;
  edited: boolean;
  addMedia: ProductVersionForm[];
  formErrorMessage: string;
}

const queryClient = new QueryClient();

const CreateProductPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CreateProductPage {...props} />
    </QueryClientProvider>
  );
};

const CreateProductPage: React.FC<OwnProps> = ({ classes }) => {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [imageURL, setImageURL] = useState('');
  const [addMedia, setAddMedia] = useState<ProductVersionForm[]>([]);
  const [product, setProduct] = useState<ProductWithPoster>({
    title: '',
    genres: [],
    language: 'English',
  });
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [edited, setEdited] = useState(false);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleFilmDetailsSubmit = async () => {
    if (!edited) {
      if (product.title === '') {
        setFormErrorMessage('Enter the film title please');
        return;
      }
      setEdited(true);
    }
    setActiveStep(activeStep + 1);
  };

  const handleFilmStatusQuery = (
    status: { index: number; status: string; id: string }[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, index) => {
        const foundStatus = status.find((s) => s.index === index);
        if (foundStatus) {
          return {
            ...media,
            status: foundStatus.status,
            vizziID: foundStatus.id,
          };
        }
        return media;
      })
    );
  };

  const handleFilmVersionAdd = (index: number, uuid: string) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            uuid,
            status: 'UPLOADING',
          };
        }
        return media;
      })
    );
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDropPoster = (acceptedFiles: File) => {
    console.log('Files dropped:', acceptedFiles);
    setProduct((prevProduct) => ({
      ...prevProduct,
      poster: acceptedFiles,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct[name];
        return updatedProduct;
      });
    } else if (name === 'year') {
      if (!isNaN(Number(value)) && !value.includes('-') && value.length <= 4) {
        // If the value is a valid year, update the 'year' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          year: parseInt(value),
        }));
      }
    } else if (name === 'duration') {
      if (!isNaN(Number(value)) && !value.includes('-')) {
        // If the value is a valid number, update the 'duration' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          duration: parseInt(value),
        }));
      }
    } else {
      // For other fields, update the product state with the new value
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleLangChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.language;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        language: newValue.name,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRatingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.contentRating;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        contentRating: newValue.code,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleGenresChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedGenres = newValue.map(
        (option: { id: string }) => option.id
      );
      setProduct((prevProduct) => ({
        ...prevProduct,
        genres: selectedGenres,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedGenres = newValue.map(
        (option: { name: string }) => option.name
      );
      setProduct((prevProduct) => ({
        ...prevProduct,
        regions: selectedGenres,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleCreateNewVersion = () => {
    setAddMedia((prevMedia) => [...prevMedia, { version: '', video: null }]);
  };

  const handleRemoveForm = (index: number) => {
    setAddMedia((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    field: keyof ProductVersionForm,
    value: string
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: value,
          };
        }
        return media;
      })
    );
  };

  const handleInputDropChange = (
    index: number,
    field: keyof ProductVersionForm,
    acceptedFiles: File[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: acceptedFiles,
          };
        }
        return media;
      })
    );
  };

  const isMediaUploaded = (): boolean => {
    return addMedia.every((media) => media.uuid !== undefined);
  };

  const mutation = useMutation(
    async (data: {
      product: ProductWithPoster;
      mediaList: ProductVersionForm[];
    }) => {
      const { poster, ...productVersionForm } = data.product;
      //for each media in mediaList, get vizziID and add it to a list
      const vizziIDList = data.mediaList
        .filter((media) => media.vizziID !== undefined)
        .map((media) => media.vizziID) as string[];
      productVersionForm.versions = vizziIDList;

      console.log(productVersionForm);
      return createProduct(productVersionForm, poster);
    },
    {
      onSuccess: () => {
        history.push('/catalogue');
        window.location.reload();
      },
      onError: (error: any) => {
        setFormErrorMessage(error.message);
      },
    }
  );

  console.log('data', addMedia, product);

  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.customGrid}>
          <Grid item container xs={12} sm={12} md={12} xl={12}>
            <Typography
              className={classes.dashboardTitle}
              variant='h4'
              sx={{ fontWeight: 'bold' }}
            >
              Add film
            </Typography>
          </Grid>
          <img src={lineSplit} className={classes.lineTop} />

          <FilmDetails
            product={product}
            handleDrop={handleDropPoster}
            handleChange={handleChange}
            handleLangChange={handleLangChange}
            handleRatingChange={handleRatingChange}
            handleGenresChange={handleGenresChange}
            handleRegionChange={handleRegionChange}
          />
          <AddMedia
            product={product}
            classes={classes}
            productMedia={addMedia}
            handleAddForm={handleCreateNewVersion}
            handleRemoveForm={handleRemoveForm}
            handleInputChange={handleInputChange}
            handleDropChange={handleInputDropChange}
            handleFilmStatusQuery={handleFilmStatusQuery}
            handleFilmVersionAdd={handleFilmVersionAdd}
          />
          <img src={lineSplit} className={classes.lineBottom} />
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Typography
                className={classes.authError}
                component='h1'
                variant='h6'
              >
                {formErrorMessage}
              </Typography>

              <button
                className={`${classes.secondaryBtn} ${classes.btnSpaces}`}
                onClick={handleGoBack}
              >
                <span className={classes.optionDescription}>
                  Back to my products
                </span>
              </button>
              <button
                className={classes.dashboardButton}
                type='submit'
                onClick={() =>
                  mutation.mutate({ product: product, mediaList: addMedia })
                }
                // disabled={
                //   addMedia.every(
                //     (item) =>
                //       item.status === 'COMPLETED' || item.status === 'STARTED'
                //   )
                //     ? false
                //     : true
                // }
                disabled={
                  addMedia.every((item) => item.status === 'UPLOADING')
                    ? false
                    : true
                }
              >
                Save
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(CreateProductPageWrapper);

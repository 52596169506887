import { Button, Grid, IconButton, ListItemIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import md5 from 'md5';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TerritoryWindow from 'components/licenses/forms/windows/TerritoryWindow';
import {
  CommercialRightModel,
  RightWindowModel,
} from 'models/LicenseWindowModel';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Styles from 'components/licenses/styles/LicenseStyles';

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  clauseName: string;
  licenseType: string;
  operationMode: string;
}

interface DispatchProps {
  addTerritoryEntryLW: (payload: any) => void;
  removeClauseEntryLW: (payload: any) => void;
  addTerritoryEntryLT: (payload: any) => void;
  removeClauseEntryLT: (payload: any) => void;
  addTerritoryEntryCL: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  clauseWindow: any;
  clausesTemplate: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class ClausesWindowBody extends React.Component<Props> {
  componentDidMount() {
    const { clausesTemplate } = this.props;
    if (this.props.operationMode === 'view') {
      for (let i = 0; i < clausesTemplate.territories.length; i++) {
        this.handleAddTerritory();
      }
    }
  }

  handleAddTerritory = () => {
    const id = md5('' + new Date().getTime());

    // Add territory entry to the menu window
    this.props.addTerritoryEntryLW({
      rightName: this.props.rightName,
      subRightName: this.props.subRightName,
      clauseName: this.props.clauseName,
      territoryId: id,
    });

    // Add territory entry to license template
    if (this.props.operationMode === 'create') {
      if (this.props.licenseType === 'template')
        this.props.addTerritoryEntryLT({
          rightName: this.props.rightName,
          subRightName: this.props.subRightName,
          clauseName: this.props.clauseName,
          territoryId: id,
        });
      if (this.props.licenseType === 'custom')
        this.props.addTerritoryEntryCL({
          rightName: this.props.rightName,
          subRightName: this.props.subRightName,
          clauseName: this.props.clauseName,
          territoryId: id,
        });
    }
  };

  AddTerritoryComponent = () => {
    return (
      <Button
        size='small'
        onClick={this.handleAddTerritory}
        className={this.props.classes.licenseButton}
      >
        <AddIcon /> Add Territory
      </Button>
    );
  };

  RemoveClauseComponent = () => {
    const handleRemovePeriodOnClick = () => {
      const payload = {
        rightName: this.props.rightName,
        subRightName: this.props.subRightName,
        clauseName: this.props.clauseName,
      };

      // Add to clausesLeft to choose
      this.props.removeClauseEntryLW(payload);

      // Remove from license window state
      if (this.props.operationMode === 'create') {
        if (this.props.licenseType === 'template')
          this.props.removeClauseEntryLT({
            rightName: this.props.rightName,
            subRightName: this.props.subRightName,
            clauseName: this.props.clauseName,
            licenseType: this.props.licenseType,
            operationMode: this.props.operationMode,
          });

        if (this.props.licenseType === 'custom')
          this.props.removeClauseEntryCL(payload);
      }
    };

    return (
      <>
        <ListItemIcon>
          <IconButton size='small' onClick={handleRemovePeriodOnClick}>
            <HighlightOffIcon />
          </IconButton>
        </ListItemIcon>
      </>
    );
  };

  getAddTerritoryVisibility = () => {
    const { clauseWindow } = this.props;

    if (clauseWindow.territoriesLeftToBeChosen.length === 0) return 'hidden';
    else {
      // encontrar no minimo uma janela de territorio sem valor
      const noValueEntry = clauseWindow.territories.find(
        (element: any) => element.territory === ''
      );

      if (
        clauseWindow.territoriesLeftToBeChosen.length === 1 &&
        noValueEntry !== undefined
      )
        return 'hidden';
      else return 'visible';
    }
  };

  /**
   * Component Render Method
   * @returns
   */
  render() {
    const {
      classes,
      clauseName,
      clauseWindow,
      clausesTemplate,
      operationMode,
    } = this.props;

    let territories = clauseWindow.territories;

    if (operationMode === 'view') {
      territories = clausesTemplate.territories;
    }

    return (
      <>
        <div>
          <div className={clsx(classes.licenseSubtitle, classes.titleMargin)}>
            Period
          </div>

          <this.AddTerritoryComponent />
          <Grid
            className={classes.territoryContainer}
            container
            item
            sm={12}
            justify='flex-start'
          >
            {territories
              ? territories.map((item: any, index: any) => {
                  return (
                    <TerritoryWindow
                      key={index}
                      rightName={this.props.rightName}
                      subRightName={this.props.subRightName}
                      clauseName={clauseName}
                      licenseType={this.props.licenseType}
                      territoryId={item.territoryId}
                      operationMode={this.props.operationMode}
                    />
                  );
                })
              : ''}
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const rightWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as CommercialRightModel;
  const clauseWindow =
    rightWindow !== undefined
      ? rightWindow[ownProps.subRightName].openTabs.find(
          (element: any) => element.clause === ownProps.clauseName
        )
      : null;

  return {
    clauseWindow: clauseWindow,
    clausesTemplate:
      state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
        ownProps.subRightName
      ].clauses[ownProps.clauseName],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addTerritoryEntryLW: LicenseWindowActions.addTerritoryEntryLW,
      removeClauseEntryLW: LicenseWindowActions.removeClauseEntryLW,

      /* LicenseTemplate */
      addTerritoryEntryLT: LicenseTemplateActions.addTerritoryEntryLT,
      removeClauseEntryLT: LicenseTemplateActions.removeClauseEntryLT,

      /* CustomLicense */
      addTerritoryEntryCL: CustomLicenseActions.addTerritoryEntryCL,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles as any)(ClausesWindowBody));

import { withStyles } from '@material-ui/styles';
import useStyles from './LoadingStyles';

function MLoading({ xs, classes }: { xs?: boolean; classes: any }) {
  let loadingClasses = classes.loading;

  if (xs) {
    loadingClasses += ' loading-xs';
  }

  const renderItems = () => {
    return Array.from({ length: 5 }, (_, index) => (
      <div
        key={index}
        className={`${classes.item} ${classes[`right${index + 1}`]} ${
          classes.rightAnimation
        }`}
      ></div>
    ));
  };

  return <div className={loadingClasses}>{renderItems()}</div>;
}

export default withStyles(useStyles)(MLoading);

import { withStyles } from '@material-ui/styles';
import React from 'react';

import { isLoggedIn } from 'services/authService';
import Footer from 'templates/footer/Footer';
import Header from 'templates/header/Header';
import SideBar from 'templates/sidebar/Sidebar';

import Styles from 'styles/styles';

interface OwnProps {
  classes: any;
  history: any;
  children: React.ReactElement;
}

class MainLayout extends React.Component<OwnProps> {
  state = {
    open: true,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { children, classes } = this.props;
    return (
      <>
        <div className={classes.root}>
          <Header
            open={this.state.open}
            handleDrawerOpen={this.handleDrawerOpen}
            history={this.props.history}
          />

          {/* Left Sidebar */}
          {isLoggedIn() ? (
            <>
              <SideBar
                open={this.state.open}
                handleDrawerClose={this.handleDrawerClose}
              />
            </>
          ) : (
            ''
          )}

          {/* Main Container */}
          <main className={classes.layoutContent}>
            <div className={classes.layoutContainer}>{children}</div>

            <Footer />
          </main>
        </div>
      </>
    );
  }
}

export default withStyles(Styles as any)(MainLayout);

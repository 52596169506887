import { GetUserInfoResponse, UsersManagementData } from 'mapfilm-api';
import { OrgModel } from 'models';
import { api } from 'services/customAxios';

const getUser = async (): Promise<GetUserInfoResponse> => {
  try {
    const res = await api.get('/auth/userInfo');
    return res.data as GetUserInfoResponse;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAllStaffUsers = async (): Promise<UsersManagementData> => {
  try {
    const res = await api.get('/admin/users');
    const staffUsers = res.data.filter((user: any) => user.role === 'staff');
    return staffUsers;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const changeUserStatus = async (id: number, status: string) => {
  try {
    const response = await api.put(
      `/admin/users/${id}`,
      {
        status: status,
      },
      {
        headers: {
          id: id.toString(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteUser = async (id: number, email: string) => {
  try {
    const response = await api.delete(`/admin/users/${id}`, {
      data: { email },
      headers: {
        id: id.toString(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getInstitutions = async (): Promise<OrgModel[]> => {
  try {
    const response = await api.get('/orgs');
    const orgs = response.data.orgs;
    return orgs;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const changePassword = async (password: string) => {
  try {
    const response = await api.put('/users/password', {
      password: password,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const changeInstitutionInfo = async (orgname: string, orgaddress: string) => {
  try {
    const response = await api.put('/users/orginfo', {
      orgname: orgname,
      orgaddress: orgaddress,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export {
  changeInstitutionInfo,
  changePassword,
  changeUserStatus,
  deleteUser,
  getAllStaffUsers,
  getInstitutions,
  getUser,
};

import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Styles from 'styles/dashboard';
// import { getProduct } from 'services/productService';

interface OwnProps {
  history: any;
  match: any;
  classes: any;
}

interface State {
  loading: boolean;
}

class LicenseTemplate extends React.Component<OwnProps, State> {
  state = {
    loading: true,
  };

  componentDidMount() {
    /*getProduct(this.props.match.params.templateId)
            .then(value => this.setState({ movieTitle: value.title }))
            .catch(err => console.error(err))*/
  }

  setLoadingState = (loading: boolean) => {
    this.setState({ loading: loading });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <>
          <div>
            <div className={classes.headerWithoutMargin}>
              <Typography
                variant='h5'
                className={classes.dashboardSubtitleBlue}
              >
                Bespoke License
              </Typography>

              <NavLink
                className={classes.dashLinkBtn}
                to={'/movies/' + this.props.match.params.templateId}
              >
                <Button className={classes.dashboardButton} variant='outlined'>
                  Go to Film Page
                </Button>
              </NavLink>
            </div>

            <LicenseWindow
              licenseType='template'
              operationMode='view'
              productId=''
              licenseId={this.props.match.params.templateId}
              loading={this.state.loading}
              setLoadingState={this.setLoadingState.bind(this)}
            />
          </div>

          <div className={classes.footerContainer}>
            <NavLink className={classes.dashLinkBtn} to='/myBespokeLicenses'>
              <Button className={classes.secondaryBtn} variant='outlined'>
                Back to My Bespoke Licenses
              </Button>
            </NavLink>
          </div>
        </>
      </React.Fragment>
    );
  }
}
export default withStyles(Styles as any)(LicenseTemplate);

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import UploadIcon from 'assets/icons/upload.png';

import { withStyles } from '@material-ui/styles';
import Styles from './DropzoneStyles';

interface DropzoneProps {
  onDrop: (acceptedFiles: File) => void;
  image: File | undefined;
  classes?: any;
}

const DropzoneArea: React.FC<DropzoneProps> = ({ onDrop, image, classes }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    if (image) {
      setSelectedImage(URL.createObjectURL(image));
    }
  }, [image]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const selectedImage = acceptedFiles[0];
        setSelectedImage(URL.createObjectURL(selectedImage));
        onDrop(selectedImage);
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'active' : ''} ${
        classes.dropzoneContainer
      }`}
    >
      <input {...getInputProps()} />
      {selectedImage ? (
        <img
          className={classes.imgSize}
          src={selectedImage ?? undefined}
          alt='Selected'
        />
      ) : (
        <p className={classes.centralize}>
          <img src={UploadIcon} alt='' />
        </p>
      )}
    </div>
  );
};

export default withStyles(Styles as any)(DropzoneArea);

import jwt_decode from 'jwt-decode';
import {
  LoginModel,
  RecoverPasswordModel,
  RegisterBuyerInstitutionModel,
  RegisterUserModel,
  ResetPasswordModel,
} from 'mapfilm-api';
import { api } from 'services/customAxios';

const login = async (user: LoginModel) => {
  const loginObject: LoginModel = {
    name: user.name,
    password: user.password,
  };

  try {
    const response = await api.post('/auth/login', loginObject);
    console.log(response.data);
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    return response;
  } catch (err: any) {
    console.log(err);
    console.log('login error');
    console.log(err.response.data.message);
    return Promise.reject(err);
  }
};

const register = async (newUser: RegisterUserModel) => {
  try {
    const response = await api.post('/auth/user/register', newUser);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response.data.message;
    return Promise.reject(
      errorMessage ? errorMessage : 'Cannot submit form with errors.'
    );
  }
};

const institutionRegister = async (newOrg: RegisterBuyerInstitutionModel) => {
  try {
    const response = await api.post('/auth/org/register', newOrg);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response.data.message;
    return Promise.reject(
      errorMessage ? errorMessage : 'Cannot submit form with errors.'
    );
  }
};

const renewAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await api.post('/auth/refreshToken', {
    refreshToken: refreshToken,
  });
  localStorage.setItem('accessToken', response.data.accessToken);
  return response.data.accessToken;
};

const recoverPassword = async (email: RecoverPasswordModel) => {
  try {
    const response = await api.post('/auth/password/recover', email);
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const resetPassword = async (resetPass: ResetPasswordModel) => {
  try {
    const response = await api.post('/auth/password/reset', resetPass);
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const logout = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log(refreshToken);
  api
    .post('/auth/logout', {
      refreshToken: refreshToken,
    })
    .then(() => {
      console.log('logged out');
    })
    .catch((err) => {
      console.log(err);
    });
  // if (!refreshToken) window.location.reload();
  // await api.post('/auth/logout', {
  //   refreshToken: refreshToken,
  // });
  localStorage.clear();
};

const verifyEmail = async (token: string) => {
  try {
    const response = await api.put('/auth/verify/' + token);
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const checkUsername = async (username: string) => {
  try {
    const response = await api.post('/auth/checkUsername', {
      username: username,
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const checkEmail = async (email: string) => {
  try {
    const response = await api.post('/auth/checkEmail', {
      email: email,
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const resendEmail = async (username: string) => {
  try {
    const response = await api.post('/auth/resendEmail', {
      username: username,
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

const isLoggedIn = () => {
  const token = localStorage.getItem('accessToken');
  return !!token;
};

const isSeller = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return false;
  const decodedToken: any = jwt_decode(token);
  return decodedToken.role === 'seller';
};

const isBuyer = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return false;
  const decodedToken: any = jwt_decode(token);
  return decodedToken.role === 'buyer';
};

export {
  checkEmail,
  checkUsername,
  institutionRegister,
  isBuyer,
  isLoggedIn,
  isSeller,
  login,
  logout,
  recoverPassword,
  register,
  renewAccessToken,
  resendEmail,
  resetPassword,
  verifyEmail,
};

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';

import DropzoneArea from 'components/controls/dropzone/Dropzone';
import TextInput from 'components/controls/textInput/TextInput';
import TextArea from 'components/controls/textarea/TextArea';

import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import AutocompleteInputSelects from 'components/controls/autocompleteInput/AutocompleteInputSelects';
import Styles from 'styles/dashboard';

import { getProductCategories } from 'services/productService';

import { ProductWithPoster } from 'models/ProductsModel';
import { content_rating, languages_list } from 'utils/utils';

import { project } from 'project';

interface Props {
  product: ProductWithPoster;
  handleDrop: any;
  handleChange: any;
  handleLangChange: any;
  handleRatingChange: any;
  handleGenresChange: any;
  handleRegionChange: any;
}

const FilmDetails: React.FC<Props> = ({
  product,
  handleDrop,
  handleChange,
  handleLangChange,
  handleRatingChange,
  handleGenresChange,
  handleRegionChange,
}) => {
  const [genreList, setGenreList] = useState<any[]>([]);
  const [selectedGenre, setSelectedGenre] = useState(
    product.genres ? product.genres[0] : null
  );
  const langOptions = languages_list;
  const selectedLangValue = langOptions.find(
    (option) => option.name === product.language
  );
  const ratingOptions = content_rating;
  const selectedRatingValue = ratingOptions.find(
    (option) => option.code === product.contentRating
  );

  const [regionsList, setRegionsList] = useState<any[]>([
    { id: '1', name: 'England' },
    { id: '2', name: 'Scotland' },
    { id: '3', name: 'Wales' },
    { id: '4', name: 'Northern Ireland' },
  ]);
  const [selectedRegions, setSelectedRegions] = useState();

  useEffect(() => {
    const fetchGenreList = async () => {
      try {
        const value = await getProductCategories();
        setGenreList(value.categories.map((category) => category));
      } catch (error) {
        console.log(error);
      }
    };
    fetchGenreList();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Grid container>
          <Grid container spacing={3}>
            {/*LEFT*/}
            <Grid item container sm={4} alignItems='flex-start'>
              <Grid item container spacing={3}>
                <Grid item sm={12}>
                  <DropzoneArea onDrop={handleDrop} image={product.poster} />
                </Grid>

                <Grid item sm={12}>
                  {project !== 'mfh' ? (
                    <AutocompleteInput
                      id='language'
                      name='language'
                      label='Language'
                      pageType='product'
                      placeholder='Select the film language'
                      options={langOptions}
                      value={selectedLangValue || null}
                      onChange={(event, newValue) =>
                        handleLangChange(event, newValue ? newValue : '')
                      }
                      auth={true}
                      getOptionLabel={(option) =>
                        option && option.name ? option.name : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      required={true}
                    />
                  ) : (
                    <AutocompleteInputSelects
                      id='regions'
                      label='Regions'
                      name='regions'
                      pageType='product'
                      value={selectedRegions}
                      options={regionsList}
                      placeholder='Select Regions'
                      disabled={false}
                      auth={true}
                      required={true}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue, reason) =>
                        handleRegionChange(
                          event,
                          newValue ? newValue : '',
                          reason
                        )
                      }
                      selectedOptions={product.regions || []}
                    />
                  )}
                </Grid>

                <Grid item sm={12}>
                  <TextInput
                    name='ISAN'
                    type='text'
                    pageType='product'
                    label='ISAN code'
                    placeholder='Enter the ISAN code'
                    value={product.ISAN ? product.ISAN : ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*RIGHT*/}
            <Grid item container sm={8} alignItems='flex-start'>
              <Grid item container spacing={3}>
                <Grid item sm={6}>
                  <TextInput
                    //id="productTitle"
                    name='title'
                    type='text'
                    pageType='product'
                    label='Title *'
                    placeholder='Enter the film title'
                    value={product.title}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextInput
                    name='director'
                    type='text'
                    pageType='product'
                    label='Director'
                    placeholder='Enter the director name'
                    value={product.director ? product.director : ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextInput
                    name='year'
                    type='number'
                    label='Year'
                    pageType='product'
                    placeholder='Enter the film year'
                    value={product.year ? product.year.toString() : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={4}>
                  {project !== 'mfh' ? (
                    <AutocompleteInput
                      id='contentRating'
                      name='contentRating'
                      label='Content rating'
                      pageType='product'
                      placeholder='Select the content rating'
                      options={ratingOptions}
                      value={selectedRatingValue || null}
                      onChange={(event, newValue) =>
                        handleRatingChange(event, newValue ? newValue : '')
                      }
                      auth={true}
                      getOptionLabel={(option) =>
                        option && option.code ? option.code : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      required={true}
                    />
                  ) : (
                    <TextInput
                      name='date'
                      type='date'
                      label='Copyright date'
                      pageType='product'
                      placeholder='Enter the copyright date'
                      value={product.date ? product.date.toString() : ''}
                      onChange={handleChange}
                    />
                  )}
                </Grid>

                <Grid item sm={4}>
                  <TextInput
                    name='duration'
                    type='number'
                    pageType='product'
                    label='Duration (min)'
                    placeholder='Enter the duration'
                    value={product.duration ? product.duration.toString() : ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AutocompleteInputSelects
                    id='genres'
                    label='Themes'
                    name='genres'
                    pageType='product'
                    //value={product.genres ? product.genres[0] : null}
                    value={selectedGenre}
                    options={genreList}
                    placeholder='Select themes'
                    disabled={false}
                    auth={true}
                    required={true}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue, reason) =>
                      handleGenresChange(
                        event,
                        newValue ? newValue : '',
                        reason
                      )
                    }
                    selectedOptions={product.genres || []}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextArea
                    name='synopsis'
                    type='text'
                    label='Synopsis'
                    pageType='product'
                    placeholder='Enter the description of the film'
                    value={product.synopsis ? product.synopsis : ''}
                    onChange={handleChange}
                    multiline={true}
                    rows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(FilmDetails);

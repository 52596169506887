import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { Link as NavLink, useParams } from 'react-router-dom';

import PasswordIcon from '@mui/icons-material/Lock';

import TextInput from 'components/controls/textInput/TextInput';

import { validateConfirmPassword, validatePassword } from 'utils/validation';

import { ResetPasswordModel } from 'mapfilm-api';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { resetPassword } from 'services/authService';

import LogoScene from 'assets/logo/logo-scene.png';
import LogoImg from 'assets/logo/mfh-logo.jpg';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { project } from 'project';

import { withStyles } from '@material-ui/styles';
import Styles from 'styles/auth';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';

interface ResetPasswordProps {
  classes?: any;
  match?: any;
}

const queryClient = new QueryClient();

const ResetPasswordWrapper: React.FC<ResetPasswordProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ResetPasswordConfirm {...props} />
    </QueryClientProvider>
  );
};

const ResetPasswordConfirm: React.FC<ResetPasswordProps> = ({ classes }) => {
  const { id } = useParams<{ id: string }>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState({
    passwordError: '',
    confirmPasswordError: '',
  });

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onBlurHandle = async (name: string) => {
    const errorName = `${name}Error`;
    let errorValue = '';
    const getelement = (form: any, name: string) => {
      return form[name];
    };

    const inputValue = getelement({ password, confirmPassword }, name);

    if (!(inputValue === '' || typeof inputValue === 'undefined')) {
      if (name === 'password') {
        errorValue = validatePassword(password);
      } else if (name === 'confirmPassword') {
        errorValue = validateConfirmPassword(password, confirmPassword);
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorName]: errorValue,
    }));
  };

  const resetPasswordMutation = useMutation(
    (resetPasswordModel: ResetPasswordModel) =>
      resetPassword(resetPasswordModel),
    {
      onError: (error: any) => {
        setError(error.message || 'An error occurred.');
      },
      onSuccess: () => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: 'Success! Password reseted.',
          showConfirmButton: false,
        }).then(() => {
          window.location.href = '/auth/login';
        });
        setError(null);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const resetData: ResetPasswordModel = {
      password: password,
      token: id,
    };
    console.log(resetData);
    resetPasswordMutation.mutate(resetData);
  };

  return (
    <div className={classes.resetFormContainer}>
      <div className={classes.resetFormBox}>
        <div className={classes.titleContainer}>
          <div className={classes.logoSection}>
            <NavLink to='/'>
              {project === 'scene' ? (
                <img style={{ width: '150px' }} src={LogoScene} alt='Logo' />
              ) : (
                <>
                  <img className='logo-img' src={LogoImg} alt='Project Logo' />
                </>
              )}
            </NavLink>
          </div>
          <Typography
            component='h1'
            variant='h5'
            classes={{ root: classes.registerTitle }}
          >
            <span className={classes.changeColor}>Reset</span> password
          </Typography>
        </div>
        <form className='auth-form'>
          <Grid className={classes.formContainer} container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                id='password'
                type={showPassword ? 'text' : 'password'}
                label='Password'
                name='password'
                placeholder='Choose your password'
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => onBlurHandle('password')}
                helperText={errors.passwordError}
                error={errors.passwordError !== ''}
                disabled={false}
                required={true}
                autoComplete='current-password'
                startAdornment={
                  <InputAdornment
                    classes={{ positionStart: classes.positionStart }}
                    position='start'
                  >
                    <PasswordIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    classes={{ positionEnd: classes.positionEndRegister }}
                    position='end'
                  >
                    <IconButton
                      classes={{ root: classes.iconButton }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id='confirmPassword'
                type={showConfirmPassword ? 'text' : 'password'}
                label='Confirm Password'
                name='confirmPassword'
                placeholder='Confirm your password'
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                disabled={false}
                required={true}
                autoComplete='current-password'
                onBlur={() => onBlurHandle('confirmPassword')}
                helperText={errors.confirmPasswordError}
                error={errors.confirmPasswordError !== ''}
                fullWidth
                startAdornment={
                  <InputAdornment
                    classes={{ positionStart: classes.positionStart }}
                    position='start'
                  >
                    <PasswordIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    classes={{ positionEnd: classes.positionEndRegister }}
                    position='end'
                  >
                    <IconButton
                      classes={{ root: classes.iconButton }}
                      aria-label='toggle password visibility'
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <div className={classes.termsLinkBtn}>
            <Button
              className={classes.resetPasswordBtn}
              variant='outlined'
              onClick={(e: any) => {
                handleSubmit(e);
              }}
              disabled={
                password === '' ||
                errors.passwordError !== '' ||
                confirmPassword === '' ||
                errors.confirmPasswordError !== '' ||
                resetPasswordMutation.isLoading
              }
            >
              {resetPasswordMutation.isLoading ? (
                <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                  <CircularProgress
                    sx={{
                      color: PRIMARY_COLOR,
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                </Box>
              ) : (
                'Reset password'
              )}
            </Button>
          </div>
          {error && (
            <Typography color='error' style={{ marginTop: '20px' }}>
              {error}
            </Typography>
          )}

          <div className={classes.signUpResetContainer}>
            <Typography className={classes.authTxt}>
              {"Don't have an account?"}
            </Typography>
            <Link
              classes={{ root: classes.authLink }}
              component={NavLink}
              to='/auth/register'
            >
              Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withStyles(Styles as any)(ResetPasswordWrapper);

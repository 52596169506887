import { Checkbox, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Styles from 'components/licenses/styles/LicenseStyles';
import { CustomTerritoryModel } from 'models/CustomLicenseModel';
import { TemplateTerritoryModel } from 'models/LicenseTemplateModel';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'redux/store';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
} from '../../../../redux/actions';

interface OwnProps {
  classes?: any;
  rightName: any;
  subRightName: string;
  clauseName: string;
  territoryId: string; // if create is from template license, else is from custom license
  territoryName: string; // Template name
  licenseType: string;
}

interface DispatchProps {
  updateExclusivityLT: (payload: any) => void;
  updateExclusivityCL: (payload: any) => void;
}

interface StateProps {
  exclusivity: boolean;
  nonExclusivity: boolean;
  exclusivityTemplate: boolean;
  nonExclusivityTemplate: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

class ExclusivityComponent extends React.Component<Props> {
  handleChange = (event: any) => {
    const payload = {
      rightName: this.props.rightName,
      subRightName: this.props.subRightName,
      clauseName: this.props.clauseName,
      territoryId: this.props.territoryId,
      exclusivity:
        event.target.name === 'exclusivity'
          ? event.target.checked
          : this.props.exclusivity, // just update if the specific input is changed
      nonExclusivity:
        event.target.name === 'nonExclusivity'
          ? event.target.checked
          : this.props.nonExclusivity,
    };

    if (this.props.licenseType === 'template')
      this.props.updateExclusivityLT(payload);

    // Only update if option is available
    if (
      (this.props.licenseType === 'custom' &&
        this.props.exclusivityTemplate &&
        event.target.name === 'exclusivity') ||
      (this.props.licenseType === 'custom' &&
        this.props.nonExclusivityTemplate &&
        event.target.name === 'nonExclusivity')
    ) {
      this.props.updateExclusivityCL(payload);
    }
  };

  /**
   * Component Render
   * @returns
   */
  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.exclusivityContainer}>
        <Typography align='left' className={classes.territoryLabel}>
          {this.props.licenseType === 'template'
            ? 'Allow Exclusivity:'
            : this.props.licenseType === 'custom'
            ? 'Exclusivity:'
            : ''}
          <Checkbox
            className={classes.checkboxIcon}
            color='default'
            name='exclusivity'
            disabled={
              this.props.licenseType === 'custom'
                ? !this.props.exclusivityTemplate
                : false
            }
            checked={this.props.exclusivity}
            onChange={this.handleChange}
            inputProps={{ 'aria-label': 'Exclusivity checkbox' }}
          />
        </Typography>
        <Typography align='left' className={classes.territoryLabel}>
          {this.props.licenseType === 'template'
            ? 'Allow Non-exclusivity:'
            : this.props.licenseType === 'custom'
            ? 'Non-exclusivity:'
            : ''}
          <Checkbox
            className={classes.checkboxIcon}
            color='default'
            name='nonExclusivity'
            disabled={
              this.props.licenseType === 'custom'
                ? !this.props.nonExclusivityTemplate
                : false
            }
            checked={this.props.nonExclusivity}
            onChange={this.handleChange}
            inputProps={{ 'aria-label': 'Non-exclusivity checkbox' }}
          />
        </Typography>
      </div>
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const territories =
    state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
      ownProps.subRightName
    ].clauses[ownProps.clauseName].territories;
  let exclusivity = false;
  let exclusivityTemplate = false;
  let nonExclusivityTemplate = false;
  let nonExclusivity = false;

  if (ownProps.licenseType === 'template') {
    const territory = territories.find(
      (element: TemplateTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );

    exclusivity =
      territory !== undefined ? territory.exclusivityAllowed : false;
    nonExclusivity =
      territory !== undefined ? territory.nonExclusivityAllowed : false;
    exclusivityTemplate = false;
    nonExclusivityTemplate = false;
  }
  if (ownProps.licenseType === 'custom') {
    const territory = territories.find(
      (element: TemplateTerritoryModel) =>
        element.territoryName === ownProps.territoryName
    );
    if (ownProps.territoryName !== '' && territory !== undefined) {
      exclusivityTemplate = territory.exclusivityAllowed;
      nonExclusivityTemplate = territory.nonExclusivityAllowed;
    }

    const customTerritory = state.customLicense.commercialRights[
      ownProps.rightName
    ][ownProps.subRightName][ownProps.clauseName].territories.find(
      (element: CustomTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );

    exclusivity =
      customTerritory !== undefined ? customTerritory.exclusivity : false;
    nonExclusivity =
      customTerritory !== undefined ? customTerritory.nonExclusivity : false;
  }
  return {
    exclusivity: exclusivity, // Input version
    nonExclusivity: nonExclusivity, // Input Version
    exclusivityTemplate: exclusivityTemplate, // Used only on Custom mode. Used to filter availability
    nonExclusivityTemplate: nonExclusivityTemplate, // Used only on Custom mode. Used to filter availability
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateExclusivityLT: LicenseTemplateActions.updateExclusivityLT,
      updateExclusivityCL: CustomLicenseActions.updateExclusivityCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles as any)(ExclusivityComponent));

import { Component } from 'react';

import LoginImage from 'assets/images/login-illustration-side.png';
import LoginScene from 'assets/images/login-side-scene.png';
import { project } from 'project';

import './AuthSplash.css';

class AuthSplash extends Component {
  render() {
    return (
      <>
        <div className='auth-splash-container'>
          <div className='splash-bkg'>
            <div className='auth-logo'>
              {/* <NavLink to='/'>
                <div className='logo-container'>
                  <img className='logo-img' src={LogoImg} alt='Project Logo' />
                  <img src={LogoTxt} alt='Project Logo Txt' />
                </div>
              </NavLink> */}
            </div>
            {project === 'scene' ? (
              <img
                className='auth-image'
                src={LoginScene}
                alt='Login Illustration'
              />
            ) : (
              <>
                <img
                  className='auth-image'
                  src={LoginImage}
                  alt='Login Illustration'
                />
                <div className='copyright-disclaimer'>
                  <p>Copyright © MAP project 2024.</p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='auth-splash-hidden'>
          <div className='splash-bkg'>
            <img
              className='auth-image'
              src={LoginImage}
              alt='Login Illustration'
            />
          </div>
        </div>
      </>
    );
  }
}

export default AuthSplash;

import { createStyles } from '@material-ui/styles';

import { SECONDARY_COLOR } from 'styles/colors';

const useStyles = createStyles({
  mapLogo: {
    position: 'absolute',
    top: '3em',
    marginTop: 0,
    '@media (max-height: 500px)': {
      display: 'none',
    },
  },
  pageContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  unsupportedContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  unsupportedIcon: {
    fontSize: 125,
    color: SECONDARY_COLOR,
  },
  unsupportedTxt: {
    marginTop: 20,
    paddingRight: 15,
    paddingLeft: 15,
    fontWeight: 'bold',
    fontSize: 26,
    letterSpacing: '0.8px',
    color: '#7A7A7A',
    textAlign: 'center',
    '@media (max-width: 500px)': {
      fontSize: 24,
      letterSpacing: 0.6,
    },
    '@media (max-height: 500px)': {
      marginTop: 10,
    },
  },
  divisor: {
    '@media (max-width: 500px)': {
      display: 'none',
    },
  },
  unsupportedDescription: {
    marginTop: 20,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 18,
    textAlign: 'center',
    '@media (max-width: 500px)': {
      fontSize: 17,
    },
    '@media (max-height: 500px)': {
      marginTop: 10,
    },
  },
});

export default useStyles;

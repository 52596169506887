import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';

import { UserModel } from 'models';
import { bindActionCreators } from 'redux';
import { UserActions } from 'redux/actions';
import DropdownOptions from 'templates/dropdown/DropdownOptions';

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Dropdown.css';

import { SECONDARY_COLOR } from 'styles/colors';

import Styles from 'templates/dropdown/DropdownStyle';

const ACTIVE_CLASS = 'show';
const INACTIVE_CLASS = '';

interface DropdownProps {
  user: UserModel;
}

interface OwnProps {
  classes?: any;
}

function Dropdown(props: DropdownProps & OwnProps) {
  const { classes, user } = props;

  const [isToggled, setToggle] = useState(false);
  const toggleMenuClass = isToggled ? ACTIVE_CLASS : INACTIVE_CLASS;

  return (
    <div className={classes.userDropdown}>
      <Typography
        className={classes.dropdownToggle}
        component='div'
        onClick={() => {
          setToggle(!isToggled);
        }}
      >
        <div className='user-icon'>
          <Avatar
            className={classes.avatar}
            color={SECONDARY_COLOR}
            name={`${user.firstname} ${user.lastname}`}
            round='50%'
            size='40'
          />
        </div>
        <span className={classes.navbarUsername}>
          {`${user.firstname} ${user.lastname}`}
        </span>

        <span className='expand-more'>
          <ExpandMoreIcon
            classes={{ root: classes.expandIcon }}
            fontSize='inherit'
          />
        </span>
      </Typography>

      <div
        className={`dropdown-menu ${classes.dropdownMenu} ${classes.dropdownMenuRight} ${toggleMenuClass}`}
      >
        <DropdownOptions setToggle={setToggle} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateUser: UserActions.updateUser }, dispatch);
};

const ConnectedDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dropdown);
const StyledDropdown = withStyles(Styles)(ConnectedDropdown);

// export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);

export default StyledDropdown;

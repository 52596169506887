import { Box } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { THIRD_COLOR } from 'styles/colors';

interface LoadingProps {
  size?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({
  size = 15,
  color = THIRD_COLOR,
}) => {
  return (
    <Box sx={{ display: 'inline', paddingTop: '8px' }}>
      <CircularProgress
        sx={{
          color: color,
          width: `${size}px !important`,
          height: `${size}px !important`,
        }}
      />
    </Box>
  );
};

export default Loading;

import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';

import { PRIMARY_COLOR, SECONDARY_COLOR } from './colors';

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
    },
    palette: {
      background: {
        default: PRIMARY_COLOR,
      },

      common: {
        black: PRIMARY_COLOR,
      },
      primary: {
        main: `${SECONDARY_COLOR}`,
      },

      secondary: {
        main: `${SECONDARY_COLOR}`,
      },

      text: {
        primary: `${PRIMARY_COLOR}`,
      },
    },
  })
);

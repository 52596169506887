import { PRIMARY_COLOR, SECONDARY_COLOR, THIRD_COLOR } from './colors';

export const FiltersSideBarStyles = {
  filterContainer: {
    borderRadius: '5px',
    backgroundColor: THIRD_COLOR,
    width: '100%',
    height: '100%',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important',
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px',
    padding: '10px 0',
    borderBottom: '1px solid #e0e0e0',
    '& button': {
      width: '100%',
      borderRadius: '5px',
      backgroundColor: PRIMARY_COLOR,
      color: THIRD_COLOR,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& button:hover': {
      backgroundColor: SECONDARY_COLOR,
      color: THIRD_COLOR,
    },
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '10px',
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    jusitfyContent: 'center',
    alignItems: 'center',
  },
  select: {
    '& .MuiInputLabel-root': {
      paddingBottom: '12px !important',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '20px',
    label: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '5px',
      '& label': {
        fontSize: '16px',
        marginRight: '5px',
      },
      '& p': {
        fontSize: '14px',
        color: 'rgb(216, 153, 111)',
        marginTop: '1px',
      },
    },
    filter: {
      width: '100%',
      '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
        {
          paddingRight: '65px !important',
        },
      '& .MuiInputLabel-root': {
        paddingBottom: '12px !important',
      },
      sliderContainer: {
        width: '100%',
        height: '48px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
      },
    },
  },
};

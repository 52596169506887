import { combineReducers } from 'redux';
import customLicenseReducer from './customLicenseReducer';
import licenseTemplateReducer from './licenseTemplateReducer';
import licenseWindowReducer from './licenseWindowReducer';
import {
  searchInputReducer,
  searchResultsReducer,
  searchStateReducer,
} from './searchReducer';
import userReducer from './userReducer';

const reducer = combineReducers({
  user: userReducer,
  licenseTemplate: licenseTemplateReducer,
  customLicense: customLicenseReducer,
  licenseWindow: licenseWindowReducer,
  searchResultsReducer: searchResultsReducer,
  searchInputReducer: searchInputReducer,
  searchStateReducer: searchStateReducer,

  /*cart: cartReducer,
    cartDrawer: cartDrawerReducer,
    purchase: purchaseReducer,
    homePage: homePageReducer*/
});

export default reducer;

import React from 'react';

import { withStyles } from '@material-ui/styles';
import combineStyles from 'utils/combineStyles';
import MovieStyles from './PStyles';

interface IGenresProps {
  genres: string[];
  classes?: any;
}

function ToggleButtonsMultiple(props: IGenresProps) {
  const { classes, genres } = props;
  // const selectedCategories = genres.filter((genre) => genre.selected === true);

  const [formats, setFormats] = React.useState(() => genres);

  const handleFormat = (event: any, newFormats: any) => {
    setFormats(newFormats);
  };

  return (
    <div>
      {genres.length === 0 ? (
        <span className={classes.description}>No categories selected</span>
      ) : (
        <div className={classes.genresContainer}>
          {/* <ToggleButtonGroup
            className={classes.buttonGroup}
            value={formats}
            onChange={handleFormat}
          >
            {genres.map((genre: string, index: number) => {
              return (
                <ToggleButton
                  classes={{
                    root: classes.toggleButton,
                    selected: classes.toggleButtonSelected,
                  }}
                  key={index}
                  value={genre}
                  disabled={true}
                >
                  {genre}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup> */}
          <div className={classes.genreFlexContainer}>
            {genres.map((genre: string, index: number) => {
              return (
                <span key={index} className={classes.genreItem}>
                  {genre}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const combinedStyles = combineStyles(MovieStyles);
export default withStyles(combinedStyles)(ToggleButtonsMultiple);

import { createAction } from '@reduxjs/toolkit';
import { GetUserInfoResponse } from 'mapfilm-api';
import { ProductModel } from 'models';
export interface Action {
  type: string;
  payload: any;
}

const ACTION_TYPES = {
  USER: {
    UPDATE_USER: 'UPDATE_USER',
  },
  SEARCH: {
    UPDATE_SEARCH_STATE: 'UPDATE_SEARCH_STATE',
    SET_SEARCH_INPUT: 'SET_SEARCH_INPUT',
    SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  },
};

class UserActions {
  static updateUser = (user: GetUserInfoResponse) => ({
    type: ACTION_TYPES.USER.UPDATE_USER,
    payload: user,
  });
}

class SearchActions {
  static updateSearchState = (searchState: boolean) => ({
    type: ACTION_TYPES.SEARCH.UPDATE_SEARCH_STATE,
    payload: searchState,
  });

  static setSearchInput = (searchInput: string) => ({
    type: ACTION_TYPES.SEARCH.SET_SEARCH_INPUT,
    payload: searchInput,
  });

  static setSearchResults = (products: ProductModel[]) => ({
    type: ACTION_TYPES.SEARCH.SET_SEARCH_RESULTS,
    payload: products,
  });
}

class CustomLicenseActions {
  /* Custom License */
  static updateCL = createAction('licenseTemplate/updateCL');

  /* Rights */
  static removeRightEntryCL = createAction(
    'licenseTemplate/removeRightEntryCL'
  );
  static saveRightEntryCL = createAction('licenseTemplate/saveRightEntryCL');

  /* Clauses */
  static removeClauseEntryCL = createAction(
    'licenseTemplate/removeClauseEntryCL'
  );
  static saveClauseEntryCL = createAction('licenseTemplate/saveClauseEntryCL');

  /* Territories Entries */
  static addTerritoryEntryCL = createAction(
    'licenseTemplate/addTerritoryEntryCL'
  );
  static removeTerritoryEntryCL = createAction(
    'licenseTemplate/removeTerritoryEntryCL'
  );

  /* Territory Input */
  static updateTerritoryCL = createAction('licenseTemplate/updateTerritoryCL');

  /* Exclusivity Input */
  static updateExclusivityCL = createAction(
    'licenseTemplate/updateExclusivityCL'
  );

  /* BlockedPeriod Input */
  static updatePeriodCL = createAction('licenseTemplate/updatePeriodCL');
  static removePeriodCL = createAction('licenseTemplate/removePeriodCL');

  /* Education Only */
  static updateEduPeriodCL = createAction('licenseTemplate/updateEduPeriodCL');
  static updateEduTypeCL = createAction('licenseTemplate/updateEduTypeCL');
  static updateEduPeriodFormatCL = createAction(
    'licenseTemplate/updateEduPeriodFormatCL'
  );
  static updateEduInstitutionCL = createAction(
    'licenseTemplate/updateEduInstitutionCL'
  );
}

class LicenseTemplateActions {
  /* License Template */
  static resetLT = createAction('licenseWindow/resetLT');
  static updateLT = createAction('licenseTemplate/updateLT');

  /* Rights */
  static addRightEntryLT = createAction('licenseTemplate/addRightEntryLT');
  static removeRightEntryLT = createAction(
    'licenseTemplate/removeRightEntryLT'
  );

  /* Clauses */
  static updateClauseLT = createAction('licenseTemplate/updateClauseLT');
  static removeClauseEntryLT = createAction(
    'licenseTemplate/removeClauseEntryLT'
  );

  /* Territories Entries */
  static addTerritoryEntryLT = createAction(
    'licenseTemplate/addTerritoryEntryLT'
  );
  static removeTerritoryEntryLT = createAction(
    'licenseTemplate/removeTerritoryEntryLT'
  );

  /* Territory Input */
  static updateTerritoryLT = createAction('licenseTemplate/updateTerritoryLT');

  /* Exclusivity Input */
  static updateExclusivityLT = createAction(
    'licenseTemplate/updateExclusivityLT'
  );

  /* Period Allowed for Licensing Input */
  static updatePeriodLT = createAction('licenseTemplate/updatePeriodLT');

  /* BlockedPeriod Input */
  static updateBlockedPeriodLT = createAction(
    'licenseTemplate/updateBlockedPeriodLT'
  );
  static removeBlockedPeriodLT = createAction(
    'licenseTemplate/removeBlockedPeriodLT'
  );

  /* Education Only */
  static updateEduTypeLT = createAction('licenseTemplate/updateEduTypeLT');
  static updateEduPeriodFormatLT = createAction(
    'licenseTemplate/updateEduPeriodFormatLT'
  );
  static updateEduFieldLT = createAction('licenseTemplate/updateEduFieldLT');
}

class LicenseWindowActions {
  /* Rights Window */
  static updateRightsCurrentOpenTabLW = createAction(
    'licenseWindow/updateRightsCurrentOpenTabLW'
  );
  static updateRightsLeftToBeChosenLW = createAction(
    'licenseWindow/updateRightsLeftToBeChosenLW'
  );
  static addRightsTabLW = createAction('licenseWindow/addRightsTabLW');
  static removeFromRightsLeftToBeChosenLW = createAction(
    'licenseWindow/removeFromRightsLeftToBeChosenLW'
  );
  static removeRightEntryLW = createAction('licenseWindow/removeRightEntryLW');

  /* Clauses Window */
  static updateClausesCurrentOpenTabLW = createAction(
    'licenseWindow/updateClausesCurrentOpenTabLW'
  );
  static updateClausesLeftToBeChosenLW = createAction(
    'licenseWindow/updateClausesLeftToBeChosenLW'
  );
  static addClausesTabLW = createAction('licenseWindow/addClausesTabLW');
  static removeFromClausesLeftToBeChosenLW = createAction(
    'licenseWindow/removeFromClausesLeftToBeChosenLW'
  );
  static removeClauseEntryLW = createAction(
    'licenseWindow/removeClauseEntryLW'
  );

  /* Territories Window */
  static addTerritoryEntryLW = createAction(
    'licenseWindow/addTerritoryEntryLW'
  );
  static removeTerritoryEntryLW = createAction(
    'licenseWindow/removeTerritoryEntryLW'
  );
  static updateTerritoryLW = createAction('licenseWindow/updateTerritoryLW');
  static removeTerritoryLW = createAction('licenseWindow/removeTerritoryLW');

  /* Territory's Blocked Periods Window */
  static addBlockedPeriodEntryLW = createAction(
    'licenseWindow/addBlockedPeriodEntryLW'
  );
  static removeBlockedPeriodEntryLW = createAction(
    'licenseWindow/removeBlockedPeriodEntryLW'
  );
}

export {
  ACTION_TYPES,
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
  SearchActions,
  UserActions,
};

import { createStyles } from '@material-ui/styles';

import { SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = createStyles({
  /* Contract Popup */
  popupContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 15,
  },
  popupHeader: {
    marginBottom: 30,
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: 25,
  },
  contractContainer: {
    maxHeight: '55vh',
    overflow: 'auto',
    textAlign: 'justify',
    fontSize: 15,
    lineHeight: '23px',
    padding: '20px',
    border: `1px solid ${SECONDARY_COLOR}`,
    borderRadius: 5,
    '& h1': {
      fontSize: 32,
    },
    '& h3': {
      marginTop: 10,
      marginBottom: 3,
      fontSize: 25,
    },
  },
  hiddenContainer: {
    position: 'relative',
    maxHeight: '55vh',
    overflow: 'hidden',
    '& > .hidden-contract': {
      visibility: 'hidden',
    },
  },
  loadingContent: {
    width: '100%',
    top: 0,
    position: 'absolute',
  },
  checkboxContainer: {
    marginTop: 5,
  },
  contractOptions: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    '& >*': {
      marginLeft: 24,
    },
  },
  closeButtonFooter: {
    minWidth: 160,
    height: 40,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: THIRD_COLOR,
    color: '#7A7A7A',
    border: '2px solid #7A7A7A',
    borderRadius: 10,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.02em',
    '&:hover': {
      color: THIRD_COLOR,
      backgroundColor: '#7A7A7A',
    },
  },
});

export default useStyles;

import {
  Button,
  IconButton,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ClausesWindow from 'components/licenses/forms/windows/ClausesWindow';
import EducationWindow from 'components/licenses/forms/windows/EducationWindow';
import Loading from 'components/loading/Loading';
import { commercialRightsDB, nonCommercialRightsDB } from 'database/rightsDB';
import {
  CustomLicenseModel,
  LicenseTemplateModel,
  LicenseWindowModel,
  UserModel,
} from 'models';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import { getBespokeLicense } from 'services/educationalLicenseService';
import { getProduct } from 'services/productService';

import DoneIcon from '@material-ui/icons/Done';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Styles from 'components/licenses/styles/LicenseStyles';
import { isBuyer } from 'services/authService';

const DEFAULT_VALUE = '+';
const EDUCATIONAL_RIGHT = 'Educational';

interface State {
  rights: string[];
  selectedSubright: string;
  licenseTemplate: LicenseTemplateModel;
}

interface OwnProps {
  classes?: any;
  productId: string;
  licenseId: string;
  loading?: boolean;
  licenseType: string; // template, custom (before was create and customize)
  operationMode: string; // view, edit, create
  setDisabledState?: (disabled: boolean) => void;
  setLoadingState?: (loading: boolean) => void;
}

interface DispatchProps {
  // License Windows
  updateRightsCurrentOpenTabLW: (payload: any) => void;
  addRightsTabLW: (payload: any) => void;
  removeFromRightsLeftToBeChosenLW: (payload: any) => void;
  removeRightEntryLW: (payload: any) => void;
  updateRightsLeftToBeChosenLW: (payload: any) => void;

  // License Templates
  addRightEntryLT: (payload: any) => void;
  updateClauseLT: (payload: any) => void;
  removeRightEntryLT: (payload: any) => void;
  updateLT: (payload: any) => void;
  resetLT: () => void;

  //Custom Licenses
  saveRightEntryCL: (payload: any) => void;
  removeRightEntryCL: (payload: any) => void;
  saveClauseEntryCL: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  customLicense: CustomLicenseModel;
  licenseWindow: LicenseWindowModel;
  user: UserModel;
  licenseTemplate: LicenseTemplateModel;
}

type Props = StateProps & DispatchProps & OwnProps;

class LicenseWindow extends React.Component<Props, State> {
  state = {
    rights: [],
    selectedSubright: '',
    licenseTemplate: this.props.licenseTemplate,
  };

  handleGetBespokeLicense = async (licenseTemplate: any) => {
    const temp = [];

    if (licenseTemplate) {
      temp.push(EDUCATIONAL_RIGHT);
    }

    console.log('licenseTemplate', licenseTemplate);
    const newEduTemplate = {
      ...this.state.licenseTemplate.nonCommercialRights.Educational,
      duration: licenseTemplate.clauses[0].duration,
      price: licenseTemplate.clauses[0].price,
    };
    const newlicenseTemplate = {
      ...this.state.licenseTemplate,
      vizziID: licenseTemplate.TitleID,
      filmTitle: licenseTemplate.Title,
      nonCommercialRights: {
        Educational: newEduTemplate,
      },
    };
    this.setState({ rights: temp, licenseTemplate: newlicenseTemplate });
    this.props.updateRightsLeftToBeChosenLW(temp);
  };

  setInputState = (name: string, value: any) => {
    this.setState((prevState) => ({
      ...prevState,
      licenseTemplate: {
        ...prevState.licenseTemplate,
        [name]: value,
      },
    }));
  };

  async componentDidMount() {
    /* Runs after the render */
    if (this.props.operationMode === 'create') {
      if (this.props.licenseType === 'custom') {
        await getBespokeLicense(this.props.productId).then(
          (value) => {
            // On run
            this.handleGetBespokeLicense(value);
          },
          (reason) => {
            // on fail
            console.log(reason);
          }
        );
      } else {
        await getProduct(this.props.productId, isBuyer()).then((value) => {
          // On run
          let temp: string[] = Object.keys(nonCommercialRightsDB);
          temp = temp.concat(Object.keys(commercialRightsDB));

          this.setState({
            rights: temp,
          });
          const newlicenseTemplate = {
            ...this.state.licenseTemplate,
            vizziID: value.id,
            filmTitle: value.title,
          };
          this.setState({ rights: temp, licenseTemplate: newlicenseTemplate });

          this.props.updateRightsLeftToBeChosenLW(temp);
        });
      }
    } else if (this.props.operationMode === 'view') {
      await getBespokeLicense(this.props.licenseId).then(
        (value) => {
          // On run

          this.handleGetBespokeLicense(value);
        },
        (reason) => {
          // on fail
          console.log(reason);
        }
      );
    }

    if (this.props.setLoadingState) {
      this.props.setLoadingState(false);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.licenseTemplate !== this.props.licenseTemplate) {
      console.log(
        "License Template after 'Apply' button press:",
        this.props.licenseTemplate
      );
    }

    if (prevProps.customLicense !== this.props.customLicense) {
      console.log(
        "Custom License after 'Apply' button press:",
        this.props.customLicense
      );
    }
  }

  componentWillUnmount() {
    const { licenseWindow } = this.props;

    for (let i = 0; i < licenseWindow.openTabs.length; i++) {
      const rightName = licenseWindow.openTabs[i].right;
      this.props.removeRightEntryLW(rightName);
    }

    for (let i = 0; i < this.state.rights.length; i++) {
      const rightName = this.state.rights[i];

      if (this.props.licenseType === 'template') {
        this.props.resetLT();

        this.props.removeRightEntryLT({
          rightName: rightName,
          licenseType: this.props.licenseType,
          operationMode: this.props.operationMode,
        });
      } else if (this.props.licenseType === 'custom') {
        this.resetCustomTree(rightName);
      }
    }
  }

  // Updates currently selected right
  selectRight = (tab: string) => {
    const {
      licenseType,
      licenseWindow,
      licenseTemplate,
      addRightsTabLW,
      updateRightsCurrentOpenTabLW,
      removeFromRightsLeftToBeChosenLW,
    } = this.props;

    if (licenseWindow.currentOpenTab !== tab) {
      // Switch selected right -> Reset window to initial state
      if (licenseWindow.currentOpenTab !== DEFAULT_VALUE) {
        this.resetRight(licenseWindow.currentOpenTab);
      }

      this.setState({ selectedSubright: '' });

      updateRightsCurrentOpenTabLW(tab);
      removeFromRightsLeftToBeChosenLW(tab);

      if (licenseType === 'template') {
        addRightsTabLW({
          newTab: tab,
          template: null,
        });
      }

      if (licenseType === 'custom')
        addRightsTabLW({
          newTab: tab,
          template: licenseTemplate,
        });
    }
  };

  selectSubright = (item: string) => {
    const { licenseType, operationMode, licenseWindow } = this.props;

    if (this.state.selectedSubright !== item) {
      this.setState({ selectedSubright: item });

      // License Templates: Since changes are only saved when the user presses "Apply"...
      // ...The data does not need to be reset
      if (
        licenseType === 'template' ||
        licenseType !== 'custom' ||
        operationMode !== 'create'
      ) {
        return;
      }

      this.resetSelectedClause(licenseWindow.currentOpenTab);
    }
  };

  resetCustomTree = (rightName: string) => {
    const { customLicense, removeClauseEntryCL, removeRightEntryCL } =
      this.props;

    const isEducational = customLicense.nonCommercialRights[rightName];

    if (isEducational) {
      return removeRightEntryCL({ rightName: rightName });
    }

    const rightCustom = customLicense.commercialRights[rightName];

    for (const subRight in rightCustom) {
      for (const clause in rightCustom[subRight]) {
        removeClauseEntryCL({
          rightName: rightName,
          subRightName: subRight,
          clauseName: clause,
        });
      }
    }
  };

  resetRight = (rightName: string) => {
    const {
      licenseType,
      operationMode,
      licenseWindow,
      customLicense,
      removeRightEntryCL,
      removeRightEntryLW,
    } = this.props;

    removeRightEntryLW(licenseWindow.currentOpenTab);

    // License Templates: Since changes are only saved when the user presses "Apply"...
    // ...The data does not need to be reset
    if (
      licenseType === 'template' ||
      licenseType !== 'custom' ||
      operationMode !== 'create'
    ) {
      return;
    }

    // If non-commercial right was previously saved, do not reset data
    if (customLicense.nonCommercialRights[rightName]?.saved) {
      return;
    }

    // Check if right is commercial or not...
    if (!customLicense.nonCommercialRights[rightName]) {
      this.resetSelectedClause(rightName);
    } else {
      removeRightEntryCL({
        rightName: rightName,
      });
    }
  };

  resetSelectedClause = (rightName: string) => {
    const { customLicense, licenseWindow, removeClauseEntryCL } = this.props;

    const currentTree = this.getCurrentTree(licenseWindow.currentOpenTab);
    const isFullTreeSeleted = currentTree.subRight && currentTree.clauseInfo;
    if (!isFullTreeSeleted) {
      return;
    }

    const subRightName = currentTree.subRight as string;
    const clauseName = currentTree.clauseInfo.clause as string;

    const clause =
      customLicense.commercialRights[rightName][subRightName][clauseName];

    // If commercial right was previously saved, do not reset data
    if (clause.saved) {
      return;
    }

    removeClauseEntryCL({
      rightName: rightName,
      subRightName: subRightName,
      clauseName: clauseName,
    });
  };

  /**
   * Function to return the style of right option
   * If right was previously saved and is non-commercial -> savedButton
   * If right was selected by user -> activeButton
   */
  selectedRightStyle = (item: string) => {
    const {
      classes,
      licenseType,
      licenseWindow,
      licenseTemplate,
      customLicense,
    } = this.props;

    if (
      licenseType === 'template' &&
      licenseTemplate.nonCommercialRights[item]?.allowed
    ) {
      return classes.savedButton;
    }

    if (
      licenseType === 'custom' &&
      customLicense.nonCommercialRights[item]?.saved
    ) {
      return classes.savedButton;
    }

    return licenseWindow.currentOpenTab === item ? classes.activeButton : '';
  };

  // Function to check if tree path was previously saved
  checkIfSaved = (item: string) => {
    const { licenseType, licenseTemplate, customLicense } = this.props;

    if (
      licenseType === 'template' &&
      licenseTemplate.nonCommercialRights[item]?.allowed
    ) {
      return true;
    } else if (
      licenseType === 'custom' &&
      customLicense.nonCommercialRights[item]?.saved
    ) {
      return true;
    } else return false;
  };

  // Function to return the style of sub-right option
  selectedSubrightStyle = (item: string) => {
    const { classes } = this.props;
    const { selectedSubright } = this.state;
    return selectedSubright === item ? classes.activeButton : '';
  };

  RemoveRightComponent = (props: any) => {
    const handleRemovePeriodOnClick = () => {
      // Add to clausesLeft to choose
      this.props.removeRightEntryLW(props.rightName);

      // Remove from license window state
      if (this.props.operationMode === 'create') {
        if (this.props.licenseType === 'template') {
          this.props.removeRightEntryLT({
            rightName: props.rightName,
            licenseType: this.props.licenseType,
            operationMode: this.props.operationMode,
          });
        } else if (this.props.licenseType === 'custom') {
          this.props.removeRightEntryCL({
            rightName: props.rightName,
          });
        }
      }
    };

    return (
      <>
        <ListItemIcon>
          <IconButton size='small' onClick={handleRemovePeriodOnClick}>
            <HighlightOffIcon />
          </IconButton>
        </ListItemIcon>
      </>
    );
  };

  /**
   * Get enabled sub-rights depending on license type
   */
  getEnabledSubRights = (rightName: string) => {
    if (this.props.licenseType === 'template')
      return Object.keys(commercialRightsDB[rightName]);
    if (this.props.licenseType === 'custom') {
      const subRights =
        this.props.licenseTemplate.commercialRights[rightName].subRights;
      const keys = Object.keys(subRights);
      const allowedClauses = [];

      for (let i = 0; i < keys.length; i++) {
        if (subRights[keys[i]].allowed) allowedClauses.push(keys[i]);
      }
      return allowedClauses;
    } else return [];
  };

  /**
   * Retrieves selected sub-right name and selected clause information
   * Clause information includes: list of territories and clause name
   */
  getCurrentTree = (rightName: string) => {
    const emptyResult = {
      clauseInfo: undefined,
      subRight: undefined,
    };

    if (rightName === EDUCATIONAL_RIGHT) {
      return emptyResult;
    }

    const currentRightValue = this.props.licenseWindow.currentOpenTab;
    const openTabs = this.props.licenseWindow.openTabs;

    if (currentRightValue === DEFAULT_VALUE || openTabs.length === 0) {
      return emptyResult;
    }

    const rightWindow = openTabs[0];

    const rightKeys = Object.keys(rightWindow);
    const rightValues = Object.values(rightWindow);

    let clauseInfo;
    let subRight;
    for (let i = 0; i < rightValues.length; i++) {
      if (rightValues[i].openTabs && rightValues[i].openTabs.length > 0) {
        subRight = rightKeys[i];
        clauseInfo = rightValues[i].openTabs[0];
      }
    }

    return {
      clauseInfo: clauseInfo,
      subRight: subRight,
    };
  };

  /**
   * Check if "Apply" button should be disabled or not
   * Commercial rights: always enabled
   * Non-commercial rights: disabled if no valid territories are selected
   */
  isButtonDisabled = (rightName: string) => {
    if (rightName === EDUCATIONAL_RIGHT) {
      return false;
    } else {
      // return true;

      const { licenseWindow } = this.props;
      const currentTree = this.getCurrentTree(licenseWindow.currentOpenTab);
      const clause = currentTree.clauseInfo;

      if (!clause) {
        return true;
      }

      const filteredTerritories = clause.territories.find(
        (element: any) => element.territory !== ''
      );

      if (filteredTerritories) {
        return false;
      }
    }
  };

  /**
   * Handle save button click
   * Saves current tree/territories selection in Redux state
   * Saves both custom licenses and license templates
   */
  handleSaveClick = () => {
    const {
      licenseWindow,
      licenseType,
      operationMode,
      addRightEntryLT,
      updateClauseLT,
      saveClauseEntryCL,
      saveRightEntryCL,
      removeRightEntryLW,
      setDisabledState,
    } = this.props;

    const currentTree = this.getCurrentTree(licenseWindow.currentOpenTab);
    const isCommercial = currentTree.subRight && currentTree.clauseInfo;

    if (licenseType === 'template' && operationMode === 'create') {
      if (isCommercial) {
        const clauseName = currentTree.clauseInfo.clause;

        updateClauseLT({
          rightName: licenseWindow.currentOpenTab,
          subRightName: currentTree.subRight,
          clauseName: clauseName,
          licenseType: licenseType,
          operationMode: operationMode,
        });
      }

      addRightEntryLT({ rightName: licenseWindow.currentOpenTab });
    } else if (licenseType === 'custom' && operationMode === 'create') {
      if (isCommercial) {
        const clauseName = currentTree.clauseInfo.clause;

        saveClauseEntryCL({
          rightName: licenseWindow.currentOpenTab,
          subRightName: currentTree.subRight,
          clauseName: clauseName,
        });
      }

      saveRightEntryCL({ rightName: licenseWindow.currentOpenTab });
    }

    if (setDisabledState) {
      setDisabledState(false);
    }

    // Reset to initial state
    removeRightEntryLW(licenseWindow.currentOpenTab);
  };

  // Rights header: Render rights options
  RightsHeader = () => {
    const { classes } = this.props;
    const { rights } = this.state;

    if (this.props.loading) {
      return <Loading />;
    }

    if (rights.length === 0) {
      return (
        <Typography className={classes.noLicenses}>
          No template was created for this product!{' '}
        </Typography>
      );
    }
    return (
      <div>
        <Typography className={classes.licenseMovieTitle}>
          {this.state.licenseTemplate.filmTitle
            ? this.state.licenseTemplate.filmTitle
            : ''}
        </Typography>

        <div className={classes.licenseTitle}>Choose Rights</div>

        <div className={classes.optionsContainer}>
          {/* Normal Tab Buttons */}
          {rights.map((item: any, index: any) => {
            return (
              <Button
                key={index}
                className={clsx(
                  classes.licenseButton,
                  this.selectedRightStyle(item)
                )}
                onClick={() => {
                  this.selectRight(item);
                }}
              >
                {item}
                {this.checkIfSaved(item) ? (
                  <DoneIcon className={classes.doneIcon} />
                ) : (
                  <></>
                )}
              </Button>
            );
          })}
        </div>
      </div>
    );
  };

  // Rights body: Render "box" with commercial/non-commercial rights costumizations
  RightsBody = () => {
    const { classes, licenseWindow, operationMode, licenseType } = this.props;
    const { selectedSubright } = this.state;
    const currentOpenTab: string = licenseWindow.currentOpenTab;

    let currentBody;
    let boxDisplay = 'none';
    let boxMargin = '150px';

    if (currentOpenTab !== DEFAULT_VALUE) {
      boxDisplay = 'block';
      boxMargin = '0px';
    }

    let BodyButton = <></>;

    if (operationMode === 'create') {
      BodyButton = (
        <Button
          className={clsx(classes.licenseButton, classes.saveBtn)}
          disabled={this.isButtonDisabled(currentOpenTab)}
          onClick={() => this.handleSaveClick()}
        >
          Apply
        </Button>
      );
    }

    if (
      this.state.rights.findIndex((element) => element === currentOpenTab) !==
      -1
    ) {
      const rightName = currentOpenTab;
      // Education Tab
      if (rightName === EDUCATIONAL_RIGHT) {
        currentBody = (
          <>
            <EducationWindow
              rightName={rightName}
              licenseType={licenseType}
              operationMode={operationMode}
              license={
                this.state.licenseTemplate.nonCommercialRights.Educational
              } //TODO: Check if there is another way to pass license info
            />

            {BodyButton}
          </>
        );
      } else {
        const subRights = this.getEnabledSubRights(rightName);
        currentBody = (
          <>
            <div className={classes.licenseSubtitle}>Choose Sub-Rights</div>

            <div className={classes.optionsContainer}>
              {/* Normal Tab Buttons */}
              {subRights.map((subright: any, index: any) => {
                return (
                  <Button
                    key={index}
                    className={clsx(
                      classes.licenseButton,
                      this.selectedSubrightStyle(subright)
                    )}
                    onClick={() =>
                      this.setState({ selectedSubright: subright })
                    }
                  >
                    {subright}
                  </Button>
                );
              })}
            </div>

            {selectedSubright ? (
              <ClausesWindow
                rightName={rightName}
                subRightName={selectedSubright}
                licenseType={this.props.licenseType}
                operationMode={this.props.operationMode}
              />
            ) : (
              <></>
            )}

            {BodyButton}
          </>
        );
      }
    }

    return (
      <div style={{ marginBottom: boxMargin }}>
        <div
          className={classes.licenseRightsBody}
          style={{ display: boxDisplay, marginBottom: boxMargin }}
        >
          {currentBody}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <this.RightsHeader />
        <this.RightsBody />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  customLicense: state.customLicense,
  licenseWindow: state.licenseWindow,
  licenseTemplate: state.licenseTemplate,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      /* License Window */
      updateRightsCurrentOpenTabLW:
        LicenseWindowActions.updateRightsCurrentOpenTabLW,
      addRightsTabLW: LicenseWindowActions.addRightsTabLW,
      removeFromRightsLeftToBeChosenLW:
        LicenseWindowActions.removeFromRightsLeftToBeChosenLW,
      removeRightEntryLW: LicenseWindowActions.removeRightEntryLW,
      updateRightsLeftToBeChosenLW:
        LicenseWindowActions.updateRightsLeftToBeChosenLW,

      /* License Template */
      addRightEntryLT: LicenseTemplateActions.addRightEntryLT,
      updateClauseLT: LicenseTemplateActions.updateClauseLT,
      removeRightEntryLT: LicenseTemplateActions.removeRightEntryLT,
      updateLT: LicenseTemplateActions.updateLT,
      resetLT: LicenseTemplateActions.resetLT,

      /* Custom License */
      saveRightEntryCL: CustomLicenseActions.saveRightEntryCL,
      removeRightEntryCL: CustomLicenseActions.removeRightEntryCL,
      saveClauseEntryCL: CustomLicenseActions.saveClauseEntryCL,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles as any)(LicenseWindow));

import { api } from 'services/customAxios';
// const getCustomLicensesBFI = async () => {
//   return await api
//     .get('/cart/bfi')
//     .then((res) => {
//       return res.data.licenseTemplates;
//     })
//     .catch((error) => {
//       console.log(error);
//       return Promise.reject(error);
//     });
// };

const getCustomLicenses = async () => {
  return await api
    .get('/cart')
    .then((res) => {
      return res.data.licenseTemplates;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const Download = (id: string) => {
  return api
    .get('/cart/download', {
      params: {
        id: id,
      },
    })
    .then((res) => {
      return res.data.licenseTemplates;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const checkPurchase = (id: string, vizziid: string): any => {
  return api
    .get('/cart/bfiByBuyerAndId', {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: {
        id: id,
        vizziid: vizziid,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const getContractPreview = (id: string): any => {
  return api
    .get('/cart/contractpreview', {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: {
        id: id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const DownloadVideo = (id: string): string => {
  return '/cart/download?id=' + id;
};

const DownloadContract = (id: string): string => {
  return '/cart/contract?id=' + id;
};

export {
  Download,
  DownloadContract,
  DownloadVideo,
  checkPurchase,
  getContractPreview,
  getCustomLicenses,
};
